import * as React from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import theme from '../../themes/default';

export const CheckboxFilterItem = ({
  type,
  children,
  onChange,
  customWidth,
  checked,
  value,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 15px',
        width: customWidth ? customWidth : '200px',
        flexShrink: '0',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          lineHeight: '1rem',
          cursor: 'pointer',
          fontSize: '0.9rem',
        }}
        onClick={(e) => {
          onChange(type, value, !checked);
        }}
      >
        {checked ? (
          <MdCheckBox size="25" color={theme.colors.accent} />
        ) : (
          <MdCheckBoxOutlineBlank size="25" />
        )}
        <span style={{ paddingLeft: '5px' }}>{children}</span>
      </div>
    </div>
  );
};
