// Copyright 2021, Karitoku, All rights reserved
import GoogleMapReact from 'google-map-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineForm } from 'react-icons/ai';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import ReactTooltip from 'react-tooltip';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import Loader from '../../components/Loader/Loader';
import PropertyCard from '../../components/PropertyCard/PropertyCard';
import StyledButton from '../../components/StyledButton/StyledButton';
import AdsCard from '../../components/Adsense/AdsCard';
import Placeholder from '../../images/home/255x200.png';
import BankIcon from '../../images/property/bank.png';
import ChildcareIcon from '../../images/property/childcare.png';
import DrugStoreIcon from '../../images/property/drugstore.png';
import EducationIcon from '../../images/property/education.png';
import HospitalIcon from '../../images/property/hospital.png';
import KobanIcon from '../../images/property/koban.png';
import LibraryIcon from '../../images/property/library.png';
import ParkIcon from '../../images/property/park.png';
import PostIcon from '../../images/property/post.png';
import RestaurantIcon from '../../images/property/restaurant.png';
import ShoppingIcon from '../../images/property/shopping.png';
import UtilIcon from '../../images/property/util.png';
import theme from '../../themes/default';
import NameCityList from '../../data/name_city.json';
import StationNameList from '../../data/station_names.json';
import {
  filterDuplicates,
  filterMissingImages,
  getClosestStation,
  getTotalExpenditure,
  saveToHistory,
} from '../../Util';

const Wrapper = styled.div`
  min-width: 300px;
  min-height: 100vh;
  font-family: ${theme.fonts.default};

  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 200ms ease-in-out;

  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};

  color: ${theme.colors.textGrey};
  background-color: white;

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 450px;
    max-height: 450px;
  }

  .image-gallery-thumbnail-image {
    object-fit: cover;
    height: 80px;
    max-height: 80px;
  }

  @media only screen and (max-width: 500px) {
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      height: 210px;
      max-height: 210px;
    }
  }
`;

const Section = styled.div`
  position: relative;
  background-color: white;
  margin: 5px;
  padding: 15px;
  overflow: hidden;

  height: ${(props) =>
    props.dropdown ? (props.toggle ? 'auto' : '2rem') : 'auto'};

  h1 {
    margin: 0 0 20px 0;
    // padding-bottom: 10px;
    padding-left: 15px;
    border-left: solid 4px ${theme.colors.cherry};
    cursor: ${(props) => (props.dropdown ? 'pointer' : 'auto')};
    font-size: 1.5em;
  }

  h1 span {
    float: right;
    margin-right: 5px;
  }

  @media only screen and (max-width: 500px) {
    margin: 0px;

    h1 {
      font-size: 1.3rem;
    }
  }
`;

const BulletPoints = styled.ul`
  li {
    float: left;
    width: calc((1000px / ${(props) => props.columns}) - 30px);
    line-height: 2rem;
    margin-left: 30px;
  }

  @media only screen and (max-width: 1100px) {
    li {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const BulletPointsWithIcons = styled.ul`
  padding-inline-start: 15px;

  li {
    float: left;
    width: 100%;
    line-height: 2rem;
    display: flex;
  }

  li img {
    height: 2rem;
    margin-right: 0.7rem;
  }

  @media only screen and (max-width: 1100px) {
    padding-inline-start: 10px;

    li {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;
  margin: 0 auto;
  padding: 10px 0px 50px 0px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    padding-left: 0;
    border-left: 0;
    margin: 0 0 0 5px;
    font-size: 1.5em;
  }

  @media only screen and (max-width: 500px) {
    h1 {
      font-size: 1.3rem;
      margin: 0;
    }
  }
`;

const Price = styled.span`
  font-size: 2rem;
  font-weight: bold;
  color: ${theme.colors.accent};
  width: 55%;

  span {
    font-size: 1.5rem;
    font-weight: normal;
    color: grey;
  }

  h2 {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.7rem;
  }
`;

const EstPrice = styled.span`
  font-size: 1.5rem;
  font-weight: normal;
  color: grey;

  h2 {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
  }

  span {
    display: flex;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

const Label = styled.div`
  background-color: ${theme.colors.accent};
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 20px;
  font-size: 0.8rem;
  margin-right: auto;
  float: right;
`;

const ExtraPrices = styled.div`
  font-size: 1rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    display: inline-block;
    padding: 5px 15px 5px 0;
  }

  span {
    padding: 0 15px 0 0;
    color: grey;
  }

  border-top: 1px solid ${theme.colors.darkGrey};
  border-bottom: 1px solid ${theme.colors.darkGrey};
`;

const ExtraDetails = styled.div`
  font-size: 1rem;
  padding: 15px 0;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    padding: 2px 15px 2px 0;
    line-height: 2rem;
    min-height: 2rem;
  }

  span {
    margin: 0 15px 0 0;
    float: left;
    width: 70px;
    color: grey;
    background-color: ${theme.colors.grey};
    padding-left: 0.25rem;
    border-radius: 3px;
  }

  a {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
  }

  border-bottom: 1px solid ${theme.colors.darkGrey};
`;

const StationDetails = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  align-items: stretch;

  span {
    margin: 0 15px 0 0;
    float: left;
    width: 70px;
    min-width: 70px;
    color: grey;
    background-color: ${theme.colors.grey};
    padding-left: 0.25rem;
    border-radius: 3px;
    display: flex;
    line-height: 2rem;
    min-height: 2rem;
    padding: 2px 0 2px 0.25rem;
  }

  ul {
    margin: 0;
    padding-left: 0px;
  }

  ul li {
    line-height: 2rem;
    display: block;
  }

  a {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Summary = styled.div`
  font-size: 1rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    padding: 2px 15px 2px 0;
    float: left;
    width: 45%;
    display: flex;
    line-height: 2rem;
    min-height: 2rem;
  }

  span {
    margin: 0 15px 0 0;
    float: left;
    width: 120px;
    color: grey;
    background-color: ${theme.colors.grey};
    padding-left: 0.25rem;
    border-radius: 3px;
  }

  p {
    margin: 0;
    flex: 1;
  }

  @media only screen and (max-width: 1100px) {
    ul li {
      width: 100%;
    }
  }
`;

const GalleryNav = styled.button`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: absolute;
  right: ${(props) => (props.right ? '0' : 'auto')};
  left: ${(props) => (props.left ? '0' : 'auto')};
  top: calc(50% - 25px);
  border: 0;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  color: ${theme.colors.accent};
  transition: all 200ms ease-in-out;
  &:hover {
    box-shadow: 0 0 10px rgba(1, 1, 1, 0.3);
  }

  @media only screen and (max-width: 500px) {
    height: 40px;
    width: 40px;
    right: ${(props) => (props.right ? '-10px' : 'auto')};
    left: ${(props) => (props.left ? '-10px' : 'auto')};
  }
`;

const StyledCarousel = styled.div`
  width: 95%;
  margin: 0 auto;

  @media only screen and (max-width: 500px) {
    width: 85%;
  }
`;

const parsePrice = (p) => {
  let parsedPrice = parseInt(p);
  if (parsedPrice >= 10000) {
    return parsedPrice / 10000 + '万';
  } else {
    return parsedPrice;
  }
};

const libraries = ['places'];

const StyledRecArrow = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? '-35px' : 'auto')};
  right: ${(props) => (props.right ? '-35px' : 'auto')};
  top: calc((100% - 30px) / 2);
  height: 30px;
  width: 30px;
  background-color: ${(props) =>
    props.disabled ? theme.colors.grey : theme.colors.darkGrey};
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: grey;
  }
`;

const RecArrow = (props) => {
  const isLeft = props.className.includes('slick-prev');
  return (
    <StyledRecArrow
      onClick={props.onClick}
      left={isLeft}
      right={!isLeft}
      disabled={props.className.includes('slick-disabled')}
    >
      {isLeft ? <FaChevronLeft /> : <FaChevronRight />}
    </StyledRecArrow>
  );
};

const recSliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <RecArrow />,
  prevArrow: <RecArrow />,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const landmarkTypes = [
  {
    name: '（コンビニ）',
    icon: ShoppingIcon,
  },
  {
    name: '（スーパー）',
    icon: ShoppingIcon,
  },
  {
    name: '（警察署・交番）',
    icon: KobanIcon,
  },
  {
    name: '（公園）',
    icon: ParkIcon,
  },
  {
    name: '（その他）',
    icon: UtilIcon,
  },
  {
    name: '（病院）',
    icon: HospitalIcon,
  },
  {
    name: '（ショッピングセンター）',
    icon: ShoppingIcon,
  },
  {
    name: '（銀行）',
    icon: BankIcon,
  },
  {
    name: '（ドラッグストア）',
    icon: DrugStoreIcon,
  },
  {
    name: '（郵便局）',
    icon: PostIcon,
  },
  {
    name: '（大学・短大）',
    icon: EducationIcon,
  },
  {
    name: '（小学校）',
    icon: EducationIcon,
  },
  {
    name: '（飲食店）',
    icon: RestaurantIcon,
  },
  {
    name: '（中学校）',
    icon: EducationIcon,
  },
  {
    name: '（役所）',
    icon: UtilIcon,
  },
  {
    name: '（幼稚園・保育園）',
    icon: ChildcareIcon,
  },
  {
    name: '（ホームセンター）',
    icon: UtilIcon,
  },
  {
    name: '（レンタルビデオ）',
    icon: ShoppingIcon,
  },
  {
    name: '（図書館）',
    icon: LibraryIcon,
  },
  {
    name: '（高校・高専）',
    icon: EducationIcon,
  },
];

const SectionRotator = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 1100px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  }
`;

const ProgressBar = styled.div`
  display: flex;
  width: 95%;
  margin: 2rem auto;
`;

const ProgressBarItem = ({ price, percent, color, label, start, end }) => {
  return price > 0 && percent > 0 ? (
    <div
      style={{
        width: percent + '%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
        {parsePrice(price).toLocaleString() + '円'}
      </span>
      <div
        style={{
          width: '100%',
          height: '2rem',
          backgroundColor: color,
          borderRadius: start
            ? '0.5rem 0 0 0.5rem'
            : end
            ? '0 0.5rem 0.5rem 0'
            : '0',
          margin: '10px 0',
        }}
      />
      <span style={{ fontSize: '0.9rem', color: 'grey', whiteSpace: 'nowrap' }}>
        {label}
      </span>
    </div>
  ) : (
    <div />
  );
};

const mapStyle = [
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#89929a',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#6b6b6b',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
];

const mapOptions = {
  styles: mapStyle,
  streetViewControl: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite'], //「地図」と「航空写真」
  },
  gestureHandling: 'cooperative',
};

const Disclaimer = styled.div`
  font-size: 0.8rem;
  color: grey;

  @media only screen and (max-width: 500px) {
    font-size: 0.6rem;
  }
`;

const CustomMarker = styled.div`
  @media only screen and (max-width: 1100px) {
    padding: 8px;
  }
  color: white;
  background: ${theme.colors.accent};
  padding: 12px;
  border: solid white 3px;
  border-radius: 100%;
  hover: cursor;
`;

const RecommendLoaderWrapper = styled.div`
  position: relative;
  height: 180px;
  margin: 16px 0px;

  div:nth-child(1) {
    background-color: initial;
  }
`;

function PropertyPage({ width, propertyId, backButton, maps, map, match }) {

  const history = useHistory();
  const [fetching, setFetching] = useState(true);
  const [bukken, setBukken] = useState(null);
  const [recs1, setRecs1] = useState([]);
  const [images, setImages] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [surrounding, setSurrounding] = useState([]);
  const [places, setPlaces] = useState(null);
  const [center, setCenter] = useState({
    lat: 35.681702400836386,
    lng: 139.76714638757284,
  });
  const [showDetails, setShowDetails] = useState(false);
  const [bid, setBid] = useState(0);

  useEffect(() => {
    document.title = 'ポルティ | 日本初、相場が分かる不動産検索サービス';
  }, []);

  const onMountMap = useCallback(() => {
    if (maps && map) {
      const placesService = new maps.places.PlacesService(map);
      setPlaces(placesService);
      setCenter({ lat: map.center.lat(), lng: map.center.lng() });
    }
  }, [maps, map]);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (bukken && places && map) {
      if (bukken.lat && bukken.lon) {
        const dest = {
          lat: bukken.lat,
          lng: bukken.lon,
        };
        map.panTo(dest);
        setCenter(dest);
      } else {
        places.findPlaceFromQuery(
          { query: bukken.address, fields: ['geometry'] },
          function callback(e) {
            if (e) {
              const dest = {
                lat: e[0].geometry.location.lat(),
                lng: e[0].geometry.location.lng(),
              };
              map.panTo(dest);
              setCenter(dest);
            }
          }
        );
      }
    }
  }, [bukken, places, map]);

  useEffect(() => {
    if(bukken){
      saveToHistory(bukken);

      var pattern = '(.{2,3}?[都道府県])((?:旭川|伊達|石狩|盛岡|奥州|田村|南相馬|那須塩原|東村山|武蔵村山|羽村|十日町|上越|富山|野々市|大町|蒲郡|四日市|姫路|大和郡山|廿日市|下松|岩国|田川|大村)市|.+?郡(?:玉村|大町|.+?)[町村]|.+?市.+?区|.+?[市区町村])(.+)';
      var result = bukken.address.match(pattern);
      var pref;

      if (result[1] && result[2] && result[1] + result[2] in NameCityList){
        var areaName = NameCityList[result[1] + result[2]].hebon;
        pref = result[1];
        bukken.addressLink = `/map/${areaName}`;
        bukken.linkAddress = result[1] + result[2];
        bukken.sufAddress = result[3];
      }

      if (pref) {
        bukken.traffics.forEach(function(traffic, index){
          var stationNameWithPref = pref + '-' + traffic.station_name + '駅';
          if (stationNameWithPref in StationNameList) {
            var stationNameHebon = StationNameList[stationNameWithPref].hebon;
            traffic.stationLink = `/map/${stationNameHebon}`;
          }
        });
      }
    }
  }, [bukken]);

  const fetchData = useCallback((bid) => {
    setFetching(true);
    fetch(`https://direct2.tekiseiyachin.com/zenkoku/detail.php?bid=` + bid, {
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((res) => {
            if (isMounted.current) {
              if (res.error_code === 100) {
                setBukken(res.bukken);
                let temp = [];
                res.bukken.images
                  .split(',')
                  .forEach((image) =>
                    temp.push({ original: image, thumbnail: image })
                  );
                setImages(temp);
                temp = [];
                res.bukken.equipment
                  .split('、')
                  .forEach((point) => temp.push(point));
                setEquipment(temp);
                temp = [];
                res.bukken.surround.split(',').forEach((point) => {
                  landmarkTypes.forEach((type) => {
                    if (point.includes(type.name)) {
                      temp.push([
                        type.icon,
                        point.replace('■', '').replace(type.name, ''),
                      ]);
                    }
                  });
                });
                setSurrounding(temp);

                fetch(
                  `https://direct2.tekiseiyachin.com/zenkoku/osusume.php?bid=` +
                    bid +
                    `&type=1`,
                  {
                    method: 'GET',
                  }
                )
                  .then((res) => {
                    if (res.ok) {
                      res.json().then((res) => {
                        if (isMounted.current) {
                          if (res.error_code === 100) {
                            setRecs1(
                              filterDuplicates(filterMissingImages(res.bukkens))
                            );
                          }
                        }
                      });
                    }
                  })
                  .catch((error) => console.log(error));

                document.title = 'ポルティ | ' + res.bukken.layout + '/' + res.bukken.floor + '階' + '/' + res.bukken.address + (res.bukken.traffics[0] ? '/' + res.bukken.traffics[0].line_name + res.bukken.traffics[0].station_name : '') + 'の賃貸・部屋探し (' + res.bukken.id + ')';
                document.getElementsByName('description').item(0).content = res.bukken.address + (res.bukken.traffics[0] ? '/' + res.bukken.traffics[0].line_name + res.bukken.traffics[0].station_name : '') + 'の割安な賃貸情報。賃貸マンション・賃貸アパートを地図で探すならポルティ。';
                document.getElementsByName('keywords').item(0).content = res.bukken.address + (res.bukken.traffics[0] ? ',' + res.bukken.traffics[0].line_name + res.bukken.traffics[0].station_name : '') + ',賃貸マンション,賃貸アパート,地図検索,ポルティ';
              } else {
              }
              setFetching(false);
            }
          });
        }
      })
      .catch((error) => console.log(error));
  }, []);

  /*
  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, []);*/

  useEffect(() => {
    setBid(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    if(bid !== 0){
      fetchData(bid);
    }
    window.scroll({ top: 0, left: 0 });
  }, [bid, fetchData]);

  const deleteItem1 = (index) => {
    recs1.splice(index, 1);
    setRecs1(recs1.slice(0));
  };

  const InfoTooltip = () => {
    return (
      <ReactTooltip
        id="help"
        place={width > 500 ? 'top' : 'left'}
        effect="solid"
        type="dark"
      >
        <span>
          相場家賃は、2000万件を超える不動産価格
          <br />
          データベースから独自に計算されています。
        </span>
      </ReactTooltip>
    );
  };

  return (
    <>
      <Wrapper show={!fetching}>
        {bukken && (
          <Content>
            <SectionRotator>
              <Section
                style={{
                  minWidth: width <= 1100 ? 'auto' : '50%',
                }}
              >
                <Header>
                  {backButton && backButton}
                  <h1>{bukken.name}</h1>
                  {width > 500 && (
                    <StyledButton
                      target="_blank"
                      rel="noopener noreferrer"
                      href={bukken.suumoLink}
                      style={{
                        backgroundColor: theme.colors.orange,
                        boxShadow: 'none',
                        margin: 0,
                        marginLeft: 'auto',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <AiOutlineForm
                          size="20"
                          style={{ marginRight: '5px' }}
                        />
                        お問い合わせ
                      </div>
                    </StyledButton>
                  )}
                </Header>
                <ImageGallery
                  items={images}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  renderRightNav={(onClick, disabled) => (
                    <GalleryNav right onClick={onClick} disabled={disabled}>
                      <FaChevronRight size="20" />
                    </GalleryNav>
                  )}
                  renderLeftNav={(onClick, disabled) => (
                    <GalleryNav left onClick={onClick} disabled={disabled}>
                      <FaChevronLeft size="20" />
                    </GalleryNav>
                  )}
                />
              </Section>
              {width <= 500 && (
                <StyledButton
                  target="_blank"
                  rel="noopener noreferrer"
                  href={bukken.suumoLink}
                  style={{
                    backgroundColor: theme.colors.orange,
                    boxShadow: 'none',
                    textAlign: 'center',
                    width: 'auto',
                    margin: '5px 10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AiOutlineForm size="20" style={{ marginRight: '5px' }} />
                    お問い合わせ
                  </div>
                </StyledButton>
              )}
              <Section style={{ width: width <= 1100 ? 'auto' : '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <Price>
                      <h2>家賃（管理費・共益費込み）</h2>
                      {bukken.set_rent.toLocaleString() + '円'}
                    </Price>
                    <EstPrice>
                      <h2>相場家賃</h2>
                      <span>
                        <s>{bukken.est_rent.toLocaleString() + '円'}</s>
                        <div
                          data-tip
                          data-for="mainHelp"
                          data-event="click"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '3px',
                            cursor: 'pointer',
                          }}
                        >
                          <MdInfoOutline color="grey" />
                        </div>
                        <ReactTooltip
                          id="mainHelp"
                          place="left"
                          effect="solid"
                          type="dark"
                          globalEventOff="click"
                        >
                          <span>
                            相場家賃は、2000万件を超える不動産価格
                            <br />
                            データベースから独自に計算されています。
                          </span>
                        </ReactTooltip>
                      </span>
                    </EstPrice>
                  </div>
                  {bukken.est_rent - bukken.set_rent > 0 && (
                    <Label>
                      {(bukken.est_rent - bukken.set_rent).toLocaleString() +
                        '円 OFF'}
                    </Label>
                  )}
                  <ExtraPrices>
                    <ul>
                      <li>
                        <span>敷金</span>
                        {bukken.shikikin === 0
                          ? '-'
                          : parsePrice(bukken.shikikin)}
                      </li>
                      <li>
                        <span>礼金</span>
                        {bukken.reikin === 0 ? '-' : parsePrice(bukken.reikin)}
                      </li>
                    </ul>
                  </ExtraPrices>

                  <ExtraDetails>
                    <ul>
                      <li>
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              minWidth: '70px',
                              marginRight: '15px',
                              color: 'grey',
                              paddingLeft: '0.25rem',
                              backgroundColor: theme.colors.grey,
                              borderRadius: '3px',
                            }}
                          >
                            所在地
                          </div>
                          {bukken.addressLink 
                            ? <div><Link to={bukken.addressLink}>{bukken.linkAddress}</Link>{bukken.sufAddress}</div>
                            : <div>{bukken.address}</div>
                          }
                        </div>
                      </li>
                      <li>
                        <span>間取り</span>
                        {bukken.layout}
                      </li>
                      <li>
                        <span>築年数</span>
                        {'築' + bukken.age + '年'}
                      </li>
                      <li>
                        <span>専有面積</span>
                        {bukken.area + ' m²'}
                      </li>
                      <li>
                        <span>階</span>
                        {bukken.floor + '階'}
                      </li>
                      <li>
                        <span>向き</span>
                        {bukken.direction === '' ? '-' : bukken.direction}
                      </li>
                      <li>
                        <span>建物種別</span>
                        {bukken.structure === 0 ? 'アパート' : 'マンション'}
                      </li>
                    </ul>
                  </ExtraDetails>

                  <StationDetails>
                    <span>駅徒歩</span>
                    <ul>
                      {bukken.traffics.map((traffic, index) => (
                        traffic.stationLink 
                          ? <li key={index}>{traffic.line_name}/<Link to={traffic.stationLink}>{traffic.station_name}駅</Link> - {traffic.minute}分</li>
                          : <li key={index}>{traffic.line_name}/{traffic.station_name}駅 - {traffic.minute}分</li>
                      ))}
                    </ul>
                  </StationDetails>
                </div>
              </Section>
            </SectionRotator>

            <Section>
              <h2>初期費用見積もり</h2>
              <div
                style={{
                  fontSize: '1.5rem',
                  marginLeft: '10px',
                  marginBottom: '20px',
                  fontWeight: 'bold',
                  color: theme.colors.cherry,
                }}
              >
                <u>
                  {'合計：約' +
                    parsePrice(getTotalExpenditure(bukken)).toLocaleString() +
                    '円'}
                </u>
              </div>

              <ProgressBar>
                <ProgressBarItem
                  price={bukken.set_rent}
                  percent={
                    (bukken.set_rent / getTotalExpenditure(bukken)) * 100
                  }
                  color={theme.colors.cherry}
                  label="前家賃"
                  start
                />
                <ProgressBarItem
                  price={bukken.shikikin}
                  percent={
                    (bukken.shikikin / getTotalExpenditure(bukken)) * 100
                  }
                  color="rgb(121,187,255)"
                  label="敷金"
                />
                <ProgressBarItem
                  price={bukken.reikin}
                  percent={(bukken.reikin / getTotalExpenditure(bukken)) * 100}
                  color="#e2e289"
                  label="礼金"
                />
                <ProgressBarItem
                  price={bukken.set_rent - bukken.maintenance_fee}
                  percent={
                    ((bukken.set_rent - bukken.maintenance_fee) /
                      getTotalExpenditure(bukken)) *
                    100
                  }
                  color="rgb(62,222,170)"
                  label="仲介手数料"
                  end
                />
              </ProgressBar>

              <Disclaimer>
                ※前家賃は賃料＋共益費・管理費の1ヶ月分として計算しています
                <br />
                ※その他初期費用や保証料によって前後する可能性がございます
                <br />
                ※仲介手数料は賃料の1ヶ月分として換算しています。不動産会社によって金額が異なるため正確な金額は不動産会社にお問合せください。
              </Disclaimer>
            </Section>

            <Section>
              <h2>概要</h2>
              <Summary>
                <ul>
                  <li>
                    <span>家賃</span>
                    <p>{`${bukken.set_rent - bukken.maintenance_fee}円`}</p>
                  </li>
                  <li>
                    <span>管理・共益費</span>
                    <p>{`${bukken.maintenance_fee}円`}</p>
                  </li>
                  <li>
                    <span>間取り詳細</span>
                    <p>{bukken.layout_detail}</p>
                  </li>
                  <li>
                    <span>階建</span>
                    <p>{bukken.total_floor + '階'}</p>
                  </li>
                  <li>
                    <span>損保</span>
                    <p>{bukken.insurance}</p>
                  </li>
                  <li>
                    <span>入居</span>
                    <p>{bukken.movein}</p>
                  </li>
                  <li>
                    <span>条件</span>
                    <p>{bukken.condition}</p>
                  </li>
                  <li>
                    <span>構造</span>
                    <p>{bukken.structure_detail}</p>
                  </li>
                  <li>
                    <span>築年月</span>
                    <p>{bukken.build_year}</p>
                  </li>
                  <li>
                    <span>駐車場</span>
                    <p>{bukken.parking}</p>
                  </li>
                  <li>
                    <span>取引態様</span>
                    <p>{bukken.contract_type}</p>
                  </li>
                  <li>
                    <span>総戸数</span>
                    <p>{bukken.total_rooms}</p>
                  </li>
                  <li>
                    <span>保証会社</span>
                    <p>{bukken.guarantor}</p>
                  </li>
                  <li>
                    <span>ほか諸費用</span>
                    <p>{bukken.other_costs}</p>
                  </li>
                </ul>
              </Summary>
            </Section>

            <Section
              dropdown={equipment.length > 9}
              toggle={showDetails}
              style={{ minHeight: '200px' }}
            >
              <h2 onClick={() => setShowDetails(!showDetails)}>
                特徴・設備
                <span>
                  {showDetails ? (
                    <FaChevronUp size="20" />
                  ) : (
                    <FaChevronDown size="20" />
                  )}
                </span>
              </h2>
              <BulletPoints columns={3}>
                {equipment.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </BulletPoints>
              {equipment.length > 9 && !showDetails && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%',
                    pointerEvents: 'none',
                    background:
                      'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 100%)',
                  }}
                />
              )}
            </Section>

            <SectionRotator reverse>
              <Section style={{ width: width > 1100 ? '100%' : 'auto' }}>
                <h2>地図</h2>
                <div style={{ height: '40vh', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_API_KEY,
                      libraries: libraries,
                    }}
                    center={center}
                    options={mapOptions}
                    zoom={16}
                    onGoogleApiLoaded={onMountMap}
                  >
                    <CustomMarker lat={center.lat} lng={center.lng} />
                  </GoogleMapReact>
                </div>
              </Section>
              {surrounding.length > 0 && surrounding[0] !== '' && (
                <Section style={{ width: width > 1100 ? '60%' : 'auto' }}>
                  <h2>周辺情報</h2>
                  <BulletPointsWithIcons>
                    {surrounding.map((e, i) => (
                      <li key={i}>
                        <img alt="" src={e[0]} />
                        {e[1]}
                      </li>
                    ))}
                  </BulletPointsWithIcons>
                </Section>
              )}
            </SectionRotator>

            <Section>
              <h2>近隣のおすすめ物件</h2>
              {recs1.length > 0 ? (
                <>
                  <StyledCarousel>
                    <Slider {...recSliderSettings}>
                      {recs1.filter(p => p.bid).slice(0, 20).map((property, index) => (
                        <div key={index}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: width <= 600 ? '0' : '5px',
                            }}
                          >
                            <PropertyCard
                              image={property.image === '' ? Placeholder : property.image}
                              property={property}
                              station={getClosestStation(property.traffics)}
                              windowWidth={width}
                              onInvalidImage={() => {
                                deleteItem1(index);
                              }}
                              forceDesktop
                              stretch
                              disableTooltip
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>
                    <InfoTooltip />
                  </StyledCarousel>
                </>
              ) : (
                <RecommendLoaderWrapper>
                  <Loader show={true} id="recommend-fetching-loader" />
                </RecommendLoaderWrapper>
              )}
            </Section>
            <Section><AdsCard /></Section>
          </Content>
        )}
      </Wrapper>
      <Loader show={fetching} />
    </>
  );
}

export default withRouter(PropertyPage);
