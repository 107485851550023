export const filterNames = [
  'minPrice',
  'maxPrice',
  'age',
  'minArea',
  'maxArea',
  'minutes',
  'layouts',
  'structures',
  'bts',
  'db',
  'pets',
  '2ndFloor',
  'iwmp',
  'aircon',
  'autoLock',
  'flooring',
  'separateSink',
  'interphone',
  'reheating',
  'security',
  'multistove',
  'citygas',
  'elevator',
  'freenet',
  'corner',
  'syskitchen',
  'brhumid',
  'parking',
  'karitoku',
  'directions',
  'materials',
];
