export const TrainLinesHeader = 
`対応路線一覧`;

export const TrainLinesText = 
`JR東海道本線(東京～熱海)(JR東海道本線)
JR山手線
JR南武線
JR武蔵野線
JR横浜線
JR横須賀線
JR相模線
JR中央線(JR中央線(快速))
JR総武線(JR総武線快速)
JR青梅線
JR宇都宮線(JR東北本線)
JR常磐線(JR常磐線(上野～取手))
JR埼京線
JR川越線
JR高崎線
JR外房線
JR内房線
JR京葉線
JR京浜東北線(JR根岸線)
JR御殿場線
JR京浜東北線(JR京浜東北線(大宮～川口))
東武東上線
東武伊勢崎線
東武野田線
東武亀戸線
東武大師線
東武越生線
西武池袋線
西武有楽町線
西武狭山線
西武新宿線
西武拝島線
西武国分寺線
西武多摩湖線
西武多摩川線
京成本線
京成押上線
京成金町線
京成千葉線
京成千原線
京王線(京王京王線)
京王相模原線
京王高尾線
京王井の頭線
京王新線
小田急線(小田急小田原線)
小田急江ノ島線
小田急多摩線
東急東横線
東急目黒線
東急田園都市線
東急大井町線
東急池上線
東急多摩川線
東急世田谷線
東急こどもの国
京急本線
京急空港線
京急大師線
京急逗子線
京急久里浜線
東京メトロ銀座線
東京メトロ丸ノ内線
東京メトロ日比谷線
東京メトロ東西線
東京メトロ千代田線
東京メトロ有楽町線
東京メトロ半蔵門線
東京メトロ南北線
東京メトロ副都心線
相鉄本線
相鉄いずみ野線
都営大江戸線
都営浅草線
都営三田線
都営新宿線
都電荒川線(都営都電荒川線)
埼玉高速鉄道
つくばエクスプレス
みなとみらい線
新交通ゆりかもめ(ゆりかもめ)
伊豆箱根大雄山線
ブルーライン(横浜市営地下鉄ブルーライン)
シーサイドライン(横浜新都市交通金沢シーサイドライン)
江ノ島電鉄線
埼玉新都市交通伊奈線
湘南モノレール
新京成線(新京成電鉄線)
千葉都市モノレール(千葉都市モノレール１号線)
千葉都市モノレール(千葉都市モノレール２号線)
多摩都市モノレール(多摩モノレール)
東京モノレール
りんかい線(東京臨海高速鉄道)
東葉高速鉄道
北総線
日暮里・舎人ライナー
グリーンライン(横浜市営地下鉄グリーンライン)`;