import * as React from 'react';
import {
  FaBuilding,
  FaChild,
  FaDumbbell,
  FaHospital,
  FaShoppingBasket,
  FaShoppingCart,
  FaTree
} from 'react-icons/fa';

const SurroundInfoMarker = ({ type, onClick }) => {
  return (
    <>
      {type === 'convenience_store' && (
        <FaShoppingBasket
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#1e90ff',
            borderRadius: '50%',
          }}
        />
      )}
      {type === 'hospital' && (
        <FaHospital
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#EF88F8',
            borderRadius: '50%',
          }}
        />
      )}
      {type === 'government' && (
        <FaBuilding
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#7b68ee',
            borderRadius: '50%',
          }}
        />
      )}
      {type === 'nursery' && (
        <FaChild
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#EAB93B',
            borderRadius: '50%',
          }}
        />
      )}
      {type === 'park' && (
        <FaTree
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#3cb371',
            borderRadius: '50%',
          }}
        />
      )}
      {type === 'gym' && (
        <FaDumbbell
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#FF9960',
            borderRadius: '50%',
          }}
        />
      )}
      {type === 'supermarket' && (
        <FaShoppingCart
          onClick={onClick}
          color={'#FFFFFF'}
          style={{
            padding: '3px',
            width: '14px',
            height: '14px',
            background: '#4682b4',
            borderRadius: '50%',
          }}
        />
      )}
    </>
  );
};

export default SurroundInfoMarker;
