export const PrivatePolicyHeader = `プライバシーポリシー`;

export const PrivatePolicyText = `ポルティグループ（以下「当社」という）は、当社の提供するサービスの利用者（以下「ユーザー」という）に関する個人情報を含んだ情報（以下「ユーザー情報」という）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。

本ポリシーは、当社が提供する各種サービスごとに定めるプライバシーポリシーおよび利用規約、その他関連する規程とともに、当社が提供する全てのサービス（以下「本サービス」という）に適用されるものとします。

１．取得する情報およびその取得方法
当社が取得するユーザー情報は、その取得方法に応じて、以下のとおりとなります。
（１）ユーザーから直接取得する情報
当社は、本サービスの利用にあたって、ユーザーから以下の情報を取得します。
・氏名
・生年月日
・性別
・メールアドレス
・住所
・サービスの利用履歴
・その他当社がアプリまたはウェブサイトにおいて取得すると定めた情報

（２）技術情報の取得
ユーザーが本サービスにアクセスする際に、当社は以下の技術情報を取得します。
・端末の種類
・端末識別子
・ブラウザの種類
・リファラ
・IPアドレス
・Cookie
・その他広告識別子

（３）ユーザーの個別同意に基づいて取得する情報
当社はユーザーから個別の同意を得られた場合、以下の情報を取得します。
・位置情報
・電話帳

（４）外部サービスとの連携により取得する情報
当社は、本サービスの利用にあたって、ユーザーが外部サービスとの連携を許可した場合、以下の情報を当該外部サービスから取得します。
・外部サービスでユーザーが利用するID
・その他外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報

２．利用目的
当社は、取得したユーザー情報を以下に定める目的のために使用します。
・サービスの提供
・本人確認、ユーザー認証
・ユーザーのトラフィック測定および行動測定
・広告の配信およびその成果確認
・本サービスに関する規約変更など重要な通知の実施
・本サービスに関するご案内・お問い合わせへの対応
・その他当社が提供する各種サービスにおいて定める目的

３．個人情報の第三者提供
当社は、ユーザー情報のうち個人情報に関して、あらかじめユーザーの同意を得ることなく、第三者に提供しません。但し、次に定める場合にはこの限りではなく、ユーザー情報を第三者に提供することがあります。
（１）外部サービスとの連携または、外部サービスを利用した認証にあたり、当該外部サービス運営会社にユーザー情報を提供する場合
（２）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して、当社が協力する必要があり、かつユーザーからの同意取得が当該事務の遂行に支障を及ぼすおそれがある場合
（３）その他法令で認められる場合

4．個人情報の開示
当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認のうえで、ユーザーに対し、遅滞なく開示を行います。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、具体的な個人情報の開示の申出先、手続、手数料につきましては、「個人情報の開示、訂正、追加、削除について」をご覧ください。

4．個人情報の訂正および利用の停止等
（１）当社は、ユーザーから、個人情報の内容が事実ではなく、訂正・追加・削除（以下「訂正等」という）を求められたときは、ユーザーご本認からのご請求であることを確認のうえ遅滞なく調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をユーザーに通知します。
（２）当社は、ユーザーから、以下の各号の理由に基づき個人情報の利用の停止または消去（以下「利用の停止等」という）を求められたときは、ユーザーご本認からのご請求であることを確認のうえ遅滞なく調査を行い、その結果に基づき、個人情報の利用の停止等を行い、その旨をユーザーに通知します。
　　①あらかじめ公表された利用目的の範囲を超えて個人情報が取扱われているという理由
　　②偽りその他の手段により個人情報が取得されたものであるという理由
（３）個人情報保護法その他の法令により、当社が訂正等または利用の停止等の義務を負わない場合、本条１項、２項の定めは適用されません。

6．改定
当社は、当社の裁量に基づいて、本ポリシーを変更します。但し、個人情報保護法その他の法令により、改定に必要な手続が定められている場合には、当該法令に基づき改定を行うものとします。
なお、当社は、ユーザーに対して本ポリシーを変更する場合には、変更後の本ポリシーの施行時期および内容を以下の方法で周知します。
・当社のウェブサイトへの掲示
・ユーザーへの通知
・その他適切な方法

7．制定日・改定日
制定：2020年2月26日`;
