// Copyright 2021, Karitoku, All rights reserved
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import StyledButton1 from '../../components/StyledButton/StyledButton1';
import GoogleLogo from '../../images/google_logo.png';
import theme from '../../themes/default';
import StationNameList from '../../data/station_names.json';
import NameCityList from '../../data/name_city.json';

const Wrapper = styled.form`
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  color: ${theme.colors.textGrey};
  text-align: left;
  height: 100%;
`;

const Input = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  font-size: 1rem;
  border: 0;
  background-color: transparent;
  outline: 0;
  padding: 0 5px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ::placeholder {
    color: #adadad;
  }
`;

const SearchResults = styled.div`
  position: absolute;
  width: 100%;
  min-height: 50px;
  max-height: 40vh;
  top: 105%;
  left: 0;
  background-color: ${theme.colors.background};
  border-radius: 5px;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  overflow-y: auto;
  z-index: 10;
`;

const SearchResult = styled.div`
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 0.9rem;
  cursor: pointer;

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    margin-right: 10px;
    flex-shrink: 0;
  }

  p {
    color: grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${theme.colors.grey};
  }
`;

const PoweredByGoogle = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  background-color: ${theme.colors.background};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    height: 50%;
    margin-right: 10px;
  }
`;

const stationListKey = 'stationList';

function SearchBar1({
  mapPlacesService = null,
  autocompleteService = null,
  onSearch,
  onFocus,
  onBlur,
  onChange,
}) {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // get station list
  const [stationList, setStationList] = useState([]);
  useEffect(() => {
    const _stationList = JSON.parse(localStorage.getItem(stationListKey));
    if (
      !_stationList ||
      (_stationList.time &&
        new Date(_stationList.time).getDate() < new Date().getDate() - 60)
    ) {
      fetch(`https://direct2.tekiseiyachin.com/zenkoku/station-list-all.php`)
        .then((res) => {
          if (res.ok) {
            res.json().then((res) => {
              if (isMounted.current) {
                setStationList(res);
                localStorage.setItem(
                  stationListKey,
                  JSON.stringify({
                    time: Date.now(),
                    list: res,
                  })
                );
              }
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      setStationList(_stationList.list);
    }
  }, []);

  const searchBar = useRef(null);

  const [stationResults, setStationResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const history = useHistory();

  const onInputChanged = useRef(
    _.debounce((value, autocompleteService, stationList, callback) => {
      if (isMounted.current) {
        if (callback) {
          callback(value);
        }
        if (autocompleteService && value !== '') {
          autocompleteService.getPlacePredictions(
            {
              input: value,
              location: new window.google.maps.LatLng(
                35.681702400836386,
                139.76714638757284
              ),
              radius: 100000,
              types: ['(regions)'],
            },
            function callback(e) {
              if (isMounted.current) {
                const _results = [];

                if (e) {
                  e.forEach((result) => {
                    _results.push({
                      main_text: result.structured_formatting.main_text,
                      sub_text: result.structured_formatting.secondary_text,
                      kana: '',
                    });
                  });
                }

                if (stationList.length > 0) {
                  stationList.forEach((station) => {
                    if (
                      (station.station_name + '駅').includes(value) ||
                      station.kana.includes(value)
                    ) {
                      _results.push({
                        main_text: station.station_name + '駅',
                        sub_text: station.pref,
                        kana: station.kana,
                      });
                    }
                  });
                }
                _results.sort((a, b) => a.main_text.localeCompare(b.main_text));
                _results.sort((a, b) =>
                  a.main_text.startsWith(value) || a.kana.startsWith(value)
                    ? b.main_text.startsWith(value)
                      ? 0
                      : -1
                    : 1
                );
                setSearchResults(_results);
              }
              // console.log(e);
            }
          );
        } else {
          setSearchResults([]);
          setStationResults([]);
        }
      }
    }, 1000)
  );

  const searchPlace = (i) => {
    if (!mapPlacesService) return;

    mapPlacesService.findPlaceFromQuery(
      { query: i, fields: ['geometry'] },
      function callback(e) {
        if (e) {
          const c = {
            lat: e[0].geometry.location.lat(),
            lng: e[0].geometry.location.lng(),
          };

          const areaValue = i.split(' ')[0]; //　iは「渋谷 日本，東京」と言う形なので「渋谷」と言うフォーマットにする
          var area_name = '';

          if (areaValue.match(/駅$/)) {
            const prefValue = i.split(' ')[1]; //　iは「渋谷 日本，東京」と言う形なので「渋谷」と言うフォーマットにする
            if (prefValue+'-'+areaValue in StationNameList){
              area_name = StationNameList[prefValue+'-'+areaValue].hebon;
            }
          }else{
            const prefValue = i.split('、')[1]; //　iは「渋谷 日本，東京」と言う形なので「渋谷」と言うフォーマットにする
            if (prefValue+areaValue in NameCityList){
              area_name = NameCityList[prefValue+areaValue].hebon;
            }
          }
          let defaultZoom = 14;
          if (area_name !== ''){
            history.push(`/map/${area_name}`);
          }else{

            if (areaValue.match(/都|道|府|県/)) {
              defaultZoom = 12;
            } else if (areaValue.includes('駅')) {
              defaultZoom = 15;
            }
            history.push(`/map?center=${c.lat},${c.lng}&zoom=${defaultZoom}`);
          }
          if (onSearch) onSearch(c, defaultZoom);
        }
      }
    );
    searchBar.current.blur();
  };

  return (
    <Wrapper id="searchBar"
      onSubmit={(e) => {
        e.preventDefault();
        searchPlace(searchBar.current.value);
      }}
    >
      <StyledButton1
        onClick={() => {}}
        style={{
          borderRadius: '1.35rem',
          border: 0,
          backgroundColor: 'transparent',
          padding: '5px',
          height: '100%',
        }}
        width="40px"
      >
        <FiSearch size="18" color={theme.colors.accent} />
      </StyledButton1>

      <Input
        ref={searchBar}
        onChange={(e) => {
          onInputChanged.current(
            e.target.value,
            autocompleteService,
            stationList,
            onChange
          );
        }}
        onFocus={() => {
          onInputChanged.current(
            searchBar.current.value,
            autocompleteService,
            stationList
          );
          setIsFocused(true);
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            if (isMounted.current) {
              setIsFocused(false);
              if (onBlur) {
                onBlur();
              }
            }
          }, 250);
        }}
        placeholder="住所、駅、地名を入力"
      />
      {(searchResults.length > 0 || stationResults.length > 0) && isFocused && (
        <SearchResults>
          {searchResults.map((result, index) => (
            <SearchResult
              key={index}
              onClick={() => {
                searchPlace(`${result.main_text} ${result.sub_text}`);
                searchBar.current.value = result.main_text;
              }}
            >
              <FiSearch
                style={{
                  minWidth: '15px',
                  maxWidth: '15px',
                  marginRight: '10px',
                }}
              />
              <h3>{result.main_text}</h3>
              <p>
                {result.sub_text && result.sub_text.replace(/^(日本、)/, '')}
              </p>
            </SearchResult>
          ))}
          <PoweredByGoogle>
            <img alt="attribution" src={GoogleLogo} />
          </PoweredByGoogle>
        </SearchResults>
      )}
    </Wrapper>
  );
}

export default SearchBar1;
