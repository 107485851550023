// Copyright 2021, Karitoku, All rights reserved

import React, { useRef } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { MdInfoOutline } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme from '../../themes/default';
import { Link } from 'react-router-dom';

const StyledPropertyCard = styled.div`
  height: ${({ rowLayout }) => (rowLayout ? 'auto' : '380px')};
  min-height: ${({ rowLayout }) => (rowLayout ? 'auto' : '380px')};
  width: 100%;
  min-width: ${({ rowLayout }) => (rowLayout ? '100%' : '240px')};
  max-width: ${({ rowLayout, stretch }) =>
    rowLayout ? '100%' : stretch ? '300px' : '240px'};
  display: flex;
  cursor: pointer;
  margin: ${({ rowLayout }) => (rowLayout ? '5px 0' : '5px')};
  box-sizing: border-box;
  padding: ${({ rowLayout }) => (rowLayout ? '0 10px' : '0')};

  transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out,
    height 200ms ease-in-out;

  border: ${(props) =>
    props.selected
      ? '3px solid ' + theme.colors.cherry
      : '2px solid transparent'};

  @media only screen and (max-width: 1000px) {
    min-width: ${(props) =>
      props.forceDesktop ? '240px' : props.rowLayout ? '100%' : '500px'};
    max-width: ${(props) =>
      props.forceDesktop
        ? props.stretch
          ? '300px'
          : '240px'
        : props.rowLayout
        ? '100%'
        : '700px'};
    height: ${(props) => (props.forceDesktop ? '360px' : 'auto')};
    min-height: ${(props) => (props.forceDesktop ? '360px' : 'auto')};
    margin: ${(props) => (props.forceDesktop ? '5px' : '5px 0')};
    padding: ${(props) => (props.forceDesktop ? '0' : '0 10px')};
  }

  @media only screen and (max-width: 500px) {
    min-width: ${(props) => (props.forceDesktop ? '240px' : '100%')};
    max-width: ${(props) =>
      props.forceDesktop ? (props.stretch ? '300px' : '240px') : '100%'};
    height: ${(props) => (props.forceDesktop ? '360px' : '210px')};
    min-height: ${(props) => (props.forceDesktop ? '360px' : '210px')};
    margin: ${(props) => (props.forceDesktop ? '5px' : '5px 0')};
    border-radius: ${(props) => (props.forceDesktop ? '8px' : 0)};
    padding: 0 10px;
  }
`;

const StyledPropertyImage = styled.div`
  width: 100%;
  border-radius: ${({ rowLayout }) => (rowLayout ? '5px' : '5px')};
  height: 180px;
  max-width: 350px;
  min-height: 180px;
  object-fit: cover;

  img {
    width: 100%;
    height: 180px;
    max-width: 350px;
    min-height: 180px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1000px) {
    width: ${(props) => (props.forceDesktop ? '100%' : '100%')};
    min-width: 100%;
    height: ${(props) => (props.forceDesktop ? '180px' : '180px')};
    min-height: ${(props) => (props.forceDesktop ? '180px' : '100px')};
    border-radius: ${(props) => (props.forceDesktop ? '5px' : '5px')};

    img {
      width: ${(props) => (props.forceDesktop ? '100%' : '100%')};
      min-width: 100%;
      height: ${(props) => (props.forceDesktop ? '180px' : '180px')};
      min-height: ${(props) => (props.forceDesktop ? '180px' : '100px')};
    }
  }

  @media only screen and (max-width: 500px) {
    height: ${(props) => (props.forceDesktop ? '180px' : '130px')};

    img {
      height: ${(props) => (props.forceDesktop ? '180px' : '130px')};
    }
  }
`;

const Rotator = styled.div`
  display: flex;
  flex-direction: ${({ rowLayout }) => (rowLayout ? 'row' : 'column')};
  @media only screen and (max-width: 1000px) {
    flex-direction: ${(props) => (props.forceDesktop ? 'column' : 'row')};
  }
`;

const StyledPropertyContent = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: rgb(57, 64, 67);
`;

const StyledPropertyTitle = styled.div`
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  color: ${theme.colors.textGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.rowLayout ? '0' : '5px 0')};

  @media only screen and (max-width: 1000px) {
    padding: ${(props) => (props.rowLayout ? '5px 0' : '5px 10px')};
  }
`;

const StyledPropertyAddress = styled.div`
  font-size: 0.8rem;
  color: grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1000px) {
    width: ${(props) => (props.forceDesktop ? 'auto' : '100%')};
    padding: ${(props) => (props.forceDesktop ? '0 10px' : 0)};
  }
`;

const StyledPropertyStation = styled.div`
  font-size: 0.9rem;
`;

const StyledPropertyDetails = styled.div`
  font-size: 0.9rem;
  color: grey;
  height: auto;

  @media only screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

const StyledPrices = styled.div`
  position: relative;
  padding: ${({ rowLayout }) => (rowLayout ? '5px' : '0px')};
  border-radius: ${({ rowLayout }) =>
    rowLayout ? '10px' : '0px 0px 10px 10px'};
  justify-content: center;
  font-size: 0.9rem;
  background-color: ${({ rowLayout }) =>
    rowLayout ? theme.colors.grey : 'transparent'};
  margin-bottom: ${({ rowLayout }) => (rowLayout ? '10px' : '0')};
  white-space: nowrap;

  @media only screen and (max-width: 1000px) {
    border-radius: ${(props) =>
      props.forceDesktop ? '0px 0px 10px 10px' : '5px'};
    margin-bottom: 5px;
    background-color: ${(props) =>
      props.forceDesktop ? 'white' : theme.colors.grey};
    padding: ${(props) => (props.forceDesktop ? '0' : '8px')};
  }
`;

const StyledPropertyEstimatedPrice = styled.div`
  display: flex;
  margin-top: 5px;
  color: ${theme.colors.textGrey};
  @media only screen and (max-width: 500px) {
  }
`;

const StyledPropertyPrice = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  color: ${(props) => (props.color ? props.color : theme.colors.textGrey)};
  @media only screen and (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

const PropertyContent = ({
  property,
  station,
  rowLayout,
  windowWidth,
  hideArrow,
  onInvalidImage,
  showNegativeLabel,
  forceDesktop,
  disableTooltip,
}) => {
  let priceDiff = property.est_rent - property.set_rent;

  const helpButton = useRef(null);

  const pid = property.id ? property.id : property.bid;

  return (
    <StyledPropertyContent to={'/chintai/' + pid} rowLayout={rowLayout}>
      {!forceDesktop && windowWidth <= 1000 && (
        <StyledPropertyTitle rowLayout={rowLayout}>
          <div
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowwrap',
              textOverflow: 'ellipsis',
              lineHeight: '30px',
              height: '30px',
              verticalAlign: 'middle',
            }}
          >
            {property.name}
          </div>
          {!hideArrow && (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <FiChevronRight size="18" />
            </div>
          )}
        </StyledPropertyTitle>
      )}

      <Rotator rowLayout={rowLayout} forceDesktop={forceDesktop}>
        <div
          style={{
            width:
              forceDesktop || (!rowLayout && windowWidth > 1000)
                ? '100%'
                : '45%',
          }}
        >
          <StyledPropertyImage
            rowLayout={rowLayout}
            forceDesktop={forceDesktop}
          >
            <img
              src={property.image}
              alt=""
              onLoad={(e) => {
                if (
                  e.target.naturalHeight === 100 &&
                  e.target.naturalWidth === 100
                ) {
                  onInvalidImage();
                }
              }}
            />
          </StyledPropertyImage>
          {!forceDesktop && windowWidth <= 1000 && (
            <StyledPropertyAddress style={{ paddingBottom: '5px' }}>
              {property.address}
            </StyledPropertyAddress>
          )}
        </div>
        <div
          style={{
            width:
              forceDesktop || (!rowLayout && windowWidth > 1000)
                ? '100%'
                : 'calc(55% - 10px)',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft:
              forceDesktop || (!rowLayout && windowWidth > 1000) ? '0' : '10px',
          }}
        >
          {(forceDesktop || windowWidth > 1000) && (
            <StyledPropertyTitle rowLayout={rowLayout}>
              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowwrap',
                  textOverflow: 'ellipsis',
                  lineHeight: '30px',
                  height: '30px',
                  verticalAlign: 'middle',
                }}
              >
                {property.name}
              </div>
              {!hideArrow && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <FiChevronRight size="18" />
                </div>
              )}
            </StyledPropertyTitle>
          )}
          <StyledPrices rowLayout={rowLayout} forceDesktop={forceDesktop}>
            <StyledPropertyEstimatedPrice>
              <div style={{ marginRight: '5px' }}>相場家賃:</div>
              {property.est_rent ? (
                <strike>
                  {parseInt(property.est_rent).toLocaleString() + '円'}
                </strike>
              ) : (
                <span>-</span>
              )}

              <div
                ref={helpButton}
                data-tip
                data-for="help"
                data-event="click"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '3px',
                }}
              >
                <MdInfoOutline color="grey" />
              </div>
              {!disableTooltip && (
                <ReactTooltip
                  id="help"
                  place={windowWidth > 500 ? 'top' : 'left'}
                  effect="solid"
                  type="dark"
                >
                  <span>
                    相場家賃は、2000万件を超える不動産価格
                    <br />
                    データベースから独自に計算されています。
                  </span>
                </ReactTooltip>
              )}
            </StyledPropertyEstimatedPrice>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    marginRight: '5px',
                    height: '100%',
                    lineHeight: '2rem',
                    verticalAlign: 'bottom',
                  }}
                >
                  掲載家賃:
                </div>
                <StyledPropertyPrice
                  color={priceDiff >= 0 ? '#ff0023' : theme.colors.negativeBlue}
                >
                  {parseInt(property.set_rent).toLocaleString() + '円'}
                </StyledPropertyPrice>
              </div>
            </div>
          </StyledPrices>
          {station && <StyledPropertyStation>{station}</StyledPropertyStation>}
          {!station && (forceDesktop || windowWidth > 1000) && (
            <StyledPropertyAddress forceDesktop={forceDesktop}>
              {property.address}
            </StyledPropertyAddress>
          )}
          <StyledPropertyDetails
            rowLayout={rowLayout}
            forceDesktop={forceDesktop}
          >
            {property.layout + ' / ' + property.area + ' m²'}{' '}
            {property.distance && (windowWidth <= 500 || !rowLayout) ? (
              <br />
            ) : (
              ' / '
            )}
            {(property.distance ? '徒歩' + property.distance + '分 / ' : '') +
              property.floor +
              '階 / ' +
              (parseInt(property.age) === 0
                ? '新築'
                : '築' + parseInt(property.age) + '年')}
          </StyledPropertyDetails>
        </div>
      </Rotator>
    </StyledPropertyContent>
  );
};

const PropertyCard = ({
  property,
  station,
  rowLayout,
  stretch,
  windowWidth,
  hideArrow,
  onInvalidImage,
  showNegativeLabel,
  forceDesktop,
  disableTooltip,
  selected,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <StyledPropertyCard
      rowLayout={rowLayout}
      stretch={stretch}
      forceDesktop={forceDesktop}
      selected={selected}
      onMouseEnter={() => {
        if (onMouseEnter) onMouseEnter();
      }}
      onMouseLeave={() => {
        if (onMouseLeave) onMouseLeave();
      }}
    >
      <PropertyContent
        property={property}
        station={station}
        rowLayout={rowLayout}
        windowWidth={windowWidth}
        hideArrow={hideArrow}
        onInvalidImage={() => {
          if (onInvalidImage) onInvalidImage();
        }}
        showNegativeLabel={showNegativeLabel}
        forceDesktop={forceDesktop}
        disableTooltip={disableTooltip}
      />
    </StyledPropertyCard>
  );
};

export default PropertyCard;
