export const TermsAndConditionsHeader = `利用規約`;

export const TermsAndConditionsText = `第1条　規約の適用
1. ポルティご利用規約（以下「本規約」といいます。）は、ポルティグループ（以下「当社」といいます。）が運営する不動産物件の推定相場情報提供サイト（以下「本サイト」といいます。）を利用するすべてのユーザーに適用されるものとし、ユーザーは、本規約に同意のうえ本サイトを利用するものとします。
2. 当社が、本サイトで提示する諸注意等(以下「諸注意等」といいます。）が存在する場合には、諸注意等はそれぞれ本規約の一部構成するものとします。
3. 当社は、ユーザーが本サイトを利用したことをもって本規約に同意したものをみなします。

第2条　著作権等
1. ユーザーは、本サイトを通じて提供されるすべてのコンテンツについて、当社の事前の承諾なく著作権法で定めるユーザー個人の私的利用の範囲を超える使用をしてはならないものとします。
2. 本条の規定に違反して紛争が生じた場合、ユーザーは、自己の費用と責任において、当該紛争を解決するとともに、当社および第三者に一切の損害を与えないものとします。

第3条　ユーザーの禁止行為
1. ユーザーは、本サイトの利用に関して、次の事項を行わないものとします。
(1) 当社が本サイト上で提供するサービスを不正の目的をもって利用する行為
(2) 他のユーザーまたは第三者の著作権、肖像権、その他知的財産権を侵害する行為
(3) 他のユーザーまたは第三者を差別、誹謗中傷、脅迫し、あるいはプライバシー、人権等を侵害する行為
(4) 政治活動、選挙活動、宗教活動
(5) 当社の名誉・信用を傷付け、信頼を毀損する行為
(6) 本サイトの運営を妨げる行為
(7) 商業目的で利用する行為(当社が認める場合を除く）
(8) その他法律、法令、公序良俗に反する行為、またはそのおそれのある行為
(9) その他当社が不適当・不適切と判断した行為
2. 前項に掲げる行為によって、当社または第三者に損害を生じた場合、ユーザーはすべての法的責任を負うものとし、当社および第三者に損害を与えないものとします。
3. 当社は、ユーザーが前2項に掲げる行為を行ったと合理的に判断した場合は、事前に通知することなく、本サイトの利用の停止をすることができるものとします。

第4条　反社会的勢力との関係の禁止
1. 当社およびユーザーは、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者(以下これらを「暴力団員等」という。)に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約いたします。
(1) 暴力団員等が経営を支配していると認められる関係を有すること
(2) 暴力団員等が経営に実質的に関与していると認められる関係を有すること
(3) 自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
(4) 暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
(5) 役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
2. 当社およびユーザーは、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約いたします。
(1) 暴力的な要求行為
(2) 法的な責任を超えた不当な要求行為
(3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為
(4) 風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
(5) その他前各号に準ずる行為
3. 当社およびユーザーは、前二項の表明に反して、相手方が暴力団員等あるいは前二項各号の一にでも該当することが判明したときは、何らの催告をせず、本規約に基づく一切の契約を解除することができ、相手方はこれになんら異議を申し立てないものとします。なお、この場合、表明に反した当事者は、期限の利益を喪失し、直ちに相手方に対する債務の弁済を行うものとします。

第5条　情報提供
当社は、本サイトの各種サービスについて、事業者から、事業者とユーザーとの契約締結状況等について情報提供を受ける場合があります。予めご了承ください。

第6条　本サイトの一時的な停止
1. 当社は、次の各号に該当する場合にはユーザーへの事前の通知することなく、本サイトおよび本サイト上で提供する各種サービスの一時的な運用の停止を行うことがあります。
(1) 本サイトの保守または仕様の変更を行う場合
(2) 天災地変その他非常事態が発生し、または発生するおそれがあり、本サイトおよび各種サービスの運営ができなくなった場合
(3) 当社が、やむをえない事由により本サイトおよび各種サービスの運営上一時的な停止が必要であると判断した場合
2. 前項に掲げる行為によって、当社または第三者に損害を生じた場合、ユーザーはすべての法的責任を負うものとし、当社および第三者に損害を与えないものとします。
3. 当社は、ユーザーが前2項に掲げる行為を行ったと合理的に判断した場合は、事前に通知することなく、本サイトの利用の停止をすることができるものとします。

第7条　当社の免責
1. 当社は、事業者の営業状況等につき調査する義務を負わないものとします。
2. 当社は、本サイトに掲載される事業者より提供された情報についてその正確性、完全性または有用性等について何ら保証しません。万一、ユーザーにつき当該情報に関して何らかのトラブルが生じた場合にも、当該トラブルが当社の責に帰すべき事由による場合を除き、当社は何ら責任を負いません。
3. 自然災害、回線の輻輳、機器の障害または保守のための停止、事業者の本サイトへの参画の終了等による情報の損失、遅延、誤送、または第三者による情報の改竄や漏洩等により発生した損害について、当社の責に帰すべき事由による場合を除き、当社は、何ら責任を負いません。また、本サイトの利用に起因するソフトウェア、ハードウェア上の事故その他の損害についても同様とします。
4. 前各項の他、本サイトの利用に起因するユーザーと第三者間の一切の紛争について、当該紛争が当社の責に帰すべき事由による場合を除き、当社は何ら責任を負いません。

第8条　規約の変更
1. 当社は、ユーザーに事前の通知をすることなく本規約および諸注意等を変更(追加・削除を含みます。以下同様。)することがあります。
2. 変更された本規約および諸注意等は、これらを本サイトに掲示した後、ユーザーが本サイトにアクセスし、利用した時点をもって承諾したものとみなします。

第9条　個人情報の取り扱い
本サイトにおける個人情報の取り扱いについては、別途定めるプライバシーポリシーによるものとします。

第10条　準拠法および裁判管轄
本規約の準拠法は日本法とし、本規約に関する一切の紛争は、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。`;
