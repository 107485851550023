// Copyright 2021, Karitoku, All rights reserved
import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import bottomImg from '../../images/about/bottom-bg.jpeg';
import mainImg from '../../images/about/main.jpg';
import people1 from '../../images/about/people1.svg';
import people2 from '../../images/about/people2.svg';
import people3 from '../../images/about/people3.svg';
import rectangle from '../../images/about/rectangle.svg';
import logo from '../../images/new-logo.svg';

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: #f4f2f2;
`;

const MainVisualWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.bottom &&
    `
    @media only screen and (max-width: 1000px) {
      margin: 0;
      height: 600px;
    }
  `}
`;

const ImageBlur = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: ${(props) => (props.bottom ? '100%' : 'calc(100vh - 60px)')};
  background: ${(props) => props.color};
  opacity: ${(props) => props.opacity};

  @media only screen and (max-width: 1000px) {
    height: ${(props) => (props.top ? 'calc(100% - 6px)' : '100%')};
  }
`;

const ImageWrapper = styled.div`
  height: calc(100vh - 60px);

  @media only screen and (max-width: 480px) {
    height: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media only screen and (max-width: 1000px) {
    width: 100%;

    margin: 0;
  }
`;

const MessageContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  p {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.1em;
    color: #ffffff;
  }

  @media screen and (max-width: 480px) {
    p {
      font-size: 15px;
      text-align: center;
      padding: 0 48px;
    }
    ${(props) => props.bottom && 'top: 55%'}
    ${(props) => props.top && 'top: 45%'}
  }
`;

const TopMessage = styled.h1`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 98px;
  letter-spacing: 0.1em;
  color: #ffffff;
  transform: matrix(0.96, 0.01, -0.29, 1, 0, 0);
  margin: 24px 0;

  @media screen and (max-width: 480px) {
    font-size: 25px;
    margin: 0;
    height: 64px;
  }
`;

const MainContainer = styled.div`
  max-width: 1000px;
  margin: auto;

  h1 {
    font-size: 32px;
    margin: 0;
    margin-top: 8px;
  }

  @media screen and (max-width: 480px) {
    max-width: 400px;

    h1 {
      font-size: 16px;
      margin: 0;
      margin-top: 8px;
    }
  }
`;

const MainTitleContainer = styled.div`
  display: inline-block;
  margin: auto;
  padding: 24px 0;

  @media screen and (max-width: 480px) {
    padding: 8px 0;
  }
`;

const TitleUnderRectangle = styled.div`
  height: 24px;
  width: 100%;
`;

const Rectangle = styled.img.attrs({
  src: rectangle,
})`
  width: 420px;
  height: auto;

  @media screen and (max-width: 480px) {
    width: 210px;
    height: auto;
  }
`;

const MainInnerContainer = styled.div`
  padding: 0 32px;
  text-align: center;
`;

const BalloonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media screen and (max-width: 480px) {
    gap: 10px;
  }
`;

const BalloonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === 'right' ? 'row-reverse' : 'row'};
`;

const BalloonImage = styled.img`
  width: 200px;
  height: 200px;

  @media screen and (max-width: 480px) {
    width: 80px;
    height: auto;
  }
`;

const Balloon = styled.div`
  position: relative;
  display: inline-block;
  margin: ${(props) =>
    props.direction === 'right' ? '4.5em 15px 1.5em 0' : '4.5em 0 1.5em 15px'};
  padding: 8px 16px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #1494dc;
  border-radius: 25px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: ${(props) => (props.direction === 'right' ? '100%' : '-30px')};
    margin-top: -15px;
    border: 15px solid transparent;
    ${(props) =>
      props.direction === 'right'
        ? 'border-left: 15px solid #1494DC;'
        : 'border-right: 15px solid #1494DC;'}
  }

  p {
    color: #ffffff;
    font-weight: bold;
    text-align: left;
  }

  @media screen and (max-width: 480px) {
    margin: ${(props) =>
      props.direction === 'right'
        ? '1.5em 15px 1.5em 0'
        : '1.5em 0 1.5em 15px'};
  }
`;

const BalloonBottom = styled.div`
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  padding: 7px 32px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #dc143c;
  border-radius: 50px;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 30px solid #dc143c;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
    margin: 0.5em 0;

    &::before {
      border: 15px solid transparent;
      border-top: 20px solid #dc143c;
    }

    p {
      margin: 4px;
      padding: 0;
    }
  }
`;

const Triangle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 100px solid #f4f2f2;
  border-right: 240px solid transparent;
  border-left: 240px solid transparent;
  z-index: 1;

  @media screen and (max-width: 480px) {
    margin: '1.5em 0';
    border-top: 50px solid #f4f2f2;
    border-right: 100px solid transparent;
    border-left: 100px solid transparent;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    color: #000000;
    font-size: 25px;
    padding-top: 16px;
  }

  @media screen and (max-width: 480px) {
    gap: 4px;

    p {
      color: #000000;
      font-size: 20px;
      width: 20px;
      padding: 0;
    }
  }
`;

const Logo = styled.img.attrs({
  src: logo,
})`
  width: 288px;

  @media screen and (max-width: 480px) {
    width: 188px;
  }
`;

const BottomCopy = styled.p`
  font-size: 28px !important;
  font-weight: bold;
  color: #000000 !important;
  margin: 0;
  padding-left: 42px;

  @media screen and (max-width: 480px) {
    font-size: 20px !important;
    padding-left: 75px !important;
  }
`;

const BottomCopyButton = styled.h2`
  border-radius: 50px;
  background: #dc143c;
  color: #ffffff;
  font-size: 32px;
  padding: 16px 100px;
  margin: 46px 0 37px;
  display: inline-block;

  @media only screen and (max-width: 480px) {
    font-size: 15px;
    padding: 8px 40px;
    margin: 16px 0;
  }
`;

const BottomMessageWrapper = styled.div`
  text-align: center;
  max-width: 1100px;
  margin: auto;

  p {
    color: #000000 !important;
    margin: 0 !important;
  }

  @media only screen and (max-width: 480px) {
    font-size: 15px !important;
  }
`;

function AboutPage() {
  useLayoutEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, []);
  return (
    <React.Fragment>
      <Wrapper>
        {/* <TopButton /> */}
        <MainVisualWrapper>
          <MessageContainer top>
            <TopMessage>住まい探しをカンタンに</TopMessage>
            <p>
              ポルティは、「理想のお部屋にカンタンに出逢える」新しい不動産検索サービスです。
            </p>
          </MessageContainer>
          <ImageWrapper>
            <Image src={mainImg} alt="city" />
          </ImageWrapper>
          <ImageBlur color="#000000" opacity="0.4" top />
        </MainVisualWrapper>
        <MainContainer>
          <MainInnerContainer>
            <MainTitleContainer>
              <h1>こんな経験はありませんか？</h1>
              <TitleUnderRectangle>
                <Rectangle />
              </TitleUnderRectangle>
            </MainTitleContainer>
            <BalloonWrapper>
              <BalloonContainer>
                <BalloonImage src={people3} />
                <Balloon>
                  <p>
                    移住や転勤、急な引っ越し。新しい住まいを探さないといけないけれど、たくさんの物件を比較検討する時間がなかった。
                  </p>
                </Balloon>
              </BalloonContainer>
              <BalloonContainer direction="right">
                <BalloonImage src={people1} />
                <Balloon direction="right">
                  <p>
                    内見できる物件数も限られていて、納得のいく物件が紹介されなかったけれど住む場所を決めないといけなかった。
                  </p>
                </Balloon>
              </BalloonContainer>
              <BalloonContainer>
                <BalloonImage src={people2} />
                <Balloon>
                  <p>
                    不動産のことや地域の相場について詳しくないから、どれが良い物件なのかわからなかった。
                  </p>
                </Balloon>
              </BalloonContainer>
            </BalloonWrapper>
            <p style={{ padding: '32px 0', fontWeight: 'bold' }}>
              住まい探しは多くの人にとって、時間に余裕がなくどう選べばいいのか分からないまま大きな金額の契約をしないといけない、非常に不安を感じるものとなっています。
            </p>
          </MainInnerContainer>
        </MainContainer>
        <div style={{ position: 'relative' }}>
          <Triangle />
        </div>
        <MainVisualWrapper bottom={true}>
          <MessageContainer bottom={true}>
            <BalloonBottom>
              <p>そのお悩み</p>
            </BalloonBottom>
            <LogoWrapper>
              <Logo />
              <p>が</p>
            </LogoWrapper>
            <BottomCopy>解決します！</BottomCopy>
            <BottomCopyButton>優れた物件と素早く出逢える</BottomCopyButton>
            <BottomMessageWrapper>
              <p>
                ポルティでは独自のAIを開発し、日本全国200万件以上の賃貸物件ひとつひとつの家賃相場を見られるようにしています。
                物件の相場賃料をひと目で分かるようにすることで、条件に合う割安で良質なお部屋とカンタンに出逢うことができます。
                ポルティは「理想のお部屋にカンタンに出逢える」を叶えることによって、新たな生活の出発点となる住まい探しを成功させることを目指しています。
              </p>
            </BottomMessageWrapper>
          </MessageContainer>
          <ImageBlur color="#e6fbff" opacity="0.9" bottom />
          <Image src={bottomImg} alt="city" />
        </MainVisualWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

export default AboutPage;
