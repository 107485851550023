// Copyright 2021, Karitoku, All rights reserved
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {
  FaBuilding,
  FaChevronLeft,
  FaChild,
  FaDumbbell,
  FaFilter,
  FaHospital,
  FaList,
  FaMap,
  FaShoppingBasket,
  FaShoppingCart,
  FaSortAmountDown,
  FaTree
} from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClose } from 'react-icons/md';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';
import SlideIn from '../../components/Animation/SlideIn';
import FilterBox from '../../components/FilterBox/FilterBox';
import Loader from '../../components/Loader/Loader';
import SurroundInfoMarker from '../../components/Map/SurroundInfoMarker';
import PropertyCard from '../../components/PropertyCard/PropertyCard';
import Searchbar1 from '../../components/Searchbar/Searchbar1';
import Select from '../../components/Select/Select';
import StyledButton1 from '../../components/StyledButton/StyledButton1';
import cityNameList from '../../data/city_names.json';
import { filterNames } from '../../data/filter_name';
import landmarks from '../../data/landmarks.json';
import nameStation from '../../data/name_station.json';
import urlFilters from '../../data/urlFilters.json';
import urlTitles from '../../data/urlTitles.json';
import Placeholder from '../../images/home/255x200.png';
import theme from '../../themes/default';
import {
  constructFilterUrl,
  filterMissingImages,
  parseFilter,
  parseUrl
} from '../../Util';
import PropertyPage from '../PropertyPage/PropertyPage';

const Wrapper = styled.div`
  height: ${(props) => (props.height ? props.height + 'px' : '100vh')};
  font-family: ${theme.fonts.default};
  display: flex;
  overscroll-behavior: contain;
`;

const propertiesPerPage = 40;

const Toolbar = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  z-index: 3;
`;

const SearchbarContainer = styled.div`
  min-width: 250px;
  max-width: 600px;
  width: 100%;
  min-height: 2.7rem;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
`;

const VerticalToolbar = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  max-width: 100px;
  margin-top: 5px;

  button {
    border: 0;
    box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
    margin-bottom: 7px;
  }
`;

const PropertyWindow = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => (props.show ? 'calc(47vh - 60px)' : '0')};
  max-height: ${(props) => (props.show ? '250px' : '0')};
  max-width: ${(props) => (props.show ? '100%' : '0')};
  top: auto;
  bottom: 10px;
  z-index: 7;

  @media only screen and (max-width: 1000px) {
    position: fixed;
    width: 100%;
    max-width: ${(props) => (props.show ? '100%' : '0')};
    top: auto;
    bottom: 10px;
  }
`;

const Price = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => (props.worth ? '#ff0023' : theme.colors.negativeBlue)};
  width: 55%;
  white-space: nowrap;

  span {
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EstPrice = styled.span`
  font-size: 1.2rem;
  font-weight: normal;
  color: grey;

  h3 {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
  }

  span {
    display: flex;
  }
`;

const ExtraPrices = styled.div`
  font-size: 0.9rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    display: inline-block;
    padding: 1% 15px 1% 0;
  }

  div {
    width: 0.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    padding: 5px;
    margin: 0 15px 0 0;
    border-radius: 50%;
    background-color: grey;
    font-size: 0.8rem;
    color: white;
    display: inline-block;
  }
`;

const PropertiesWindowTitle = styled.div`
  min-height: 75px;
  height: 75px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  z-index: 5;
  font-weight: bold;
  font-size: 1.1rem;
  color: ${theme.colors.textGrey};
  padding: 0 15px;

  span {
    width: 65%;
  }
`;

const PropertiesWindowList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

const Properties = styled.div`
  position: relative;
  min-width: 750px;
  box-shadow: -2px 2px 5px 0 rgb(0 0 0 / 40%);
  background-color: white;
  border-top: 1px solid ${theme.colors.grey};

  @media only screen and (max-width: 1350px) {
    min-width: 500px;
  }

  @media only screen and (max-width: 900px) {
    position: absolute;
    min-width: auto;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 5;
  }
`;

const Pager = styled.div`
  display: flex;
  margin: 1rem auto;

  @media only screen and (max-width: 900px) {
    margin-bottom: 150px;
  }
`;

const PageButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  border-radius: 1.2rem;
  margin: 5px;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) =>
    props.selected ? theme.colors.darkGrey : theme.colors.textGrey};
  cursor: pointer;
  pointer-events: ${(props) => (props.selected ? 'none' : 'auto')};
  border: ${(props) =>
    props.selected
      ? '2px solid ' + theme.colors.darkGrey
      : '2px solid transparent'};
  transition: all 200ms;

  &:hover {
    border: ${(props) =>
      props.selected
        ? '2px solid ' + theme.colors.darkGrey
        : '2px solid' + theme.colors.accent};
  }
`;

const Toggle = styled.div`
  position: fixed;
  display: flex;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  border-radius: 7px;
  height: 2.9rem;
  background-color: ${(props) =>
    props.listMode ? theme.colors.textGrey : 'white'};
  color: ${(props) => (props.listMode ? 'white' : theme.colors.textGrey)};
  opacity: ${(props) => (props.listMode ? 0.9 : 1)};
  align-items: center;
  z-index: 6;

  button {
    height: 100%;
    width: 100px;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: transparent;
    border-radius: 7px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${(props) => (props.listMode ? 'white' : theme.colors.textGrey)};
  }

  span {
    font-size: 0.9rem;
    padding: 0 18px;
    border-left: solid 1px ${theme.colors.darkGrey};
  }
`;

const PropertyCounter = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 18px;
  left: 15px;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.8rem;
  padding: 5px 10px;
  text-align: center;
  max-width: 25%;
  z-index: 2;
`;

const FiltersWindow = styled.div`
  position: relative;
  left: 10px;
  top: 10px;
  bottom: 20%;
  width: 400px;
  z-index: 10;
  background-color: white;
  overflow-y: ${(props) => (props.show ? 'auto' : 'hidden')};
  height: ${(props) => (props.show ? '80%' : '0')};
  border-radius: 5px;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);

  @media only screen and (max-width: 1000px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: ${(props) => (props.show ? '100%' : '0')};
    width: 100%;
    border-radius: 0;
  }
`;

const FiltersWindowHeader = styled.div`
  height: 50px;
  background-color: ${theme.colors.accent};
  color: white;
  font-size: 1rem;
  font-weight: bold;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
`;

const FiltersWindowList = styled.div`
  overflow-y: scroll;
  height: calc(100% - 50px);
  margin-top: 50px;
`;

const CloseFilterSettingsButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0;
  width: 25px;
  height: 25px;
  padding: 0;
  background-color: transparent;
  color: white;
  z-index: 6;
  cursor: pointer;
`;

const FilterButton = styled.button`
  position: relative;
  min-width: 140px;
  height: 2.7rem;
  background-color: white;
  border: solid 1px ${theme.colors.darkGrey};
  border-radius: 5px;
  text-align: left;
  padding: 5px 10px;

  color: ${theme.colors.textGrey};
  font-size: 0.9rem;
  font-weight: bold;

  cursor: pointer;

  transition: all 200ms;
`;

const StyledCard = styled(Link)`
  position: relative;
  height: calc(47vh - 60px);
  max-height: 250px;
  width: calc(100% - 10px);
  max-width: 400px;
  margin: 5px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  overflow: hidden;
  cursor: pointer;

  img {
    height: 50%;
    width: 100%;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
  }
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  height: 50%;
  flex-shrink: 0;
`;

const Card = ({ property, width, color, onClick }) => {
  return (
    <StyledCard to={'/chintai/' + property.id}>
      <img alt="" src={property.image} />
      <StyledCardContent>
        <div style={{ display: 'flex' }}>
          <Price
            worth={property.est_rent && property.set_rent < property.est_rent}
          >
            <h3>家賃（管理費・共益費込み）</h3>
            <span>{property.set_rent.toLocaleString() + '円'}</span>
          </Price>
          <EstPrice>
            <h3>相場家賃</h3>
            <span>
              {property.est_rent ? (
                <s>{parseInt(property.est_rent).toLocaleString() + '円'}</s>
              ) : (
                '-'
              )}
            </span>
          </EstPrice>
        </div>
        <ExtraPrices>
          <ul>
            <li>
              <div>敷</div>
              {property.shikikin === 0 ? '-' : parsePrice(property.shikikin)}
            </li>
            <li>
              <div>礼</div>
              {property.reikin === 0 ? '-' : parsePrice(property.reikin)}
            </li>
          </ul>
        </ExtraPrices>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.9rem',
            color: 'grey',
          }}
        >
          <div>
            {property.layout + ' / ' + property.area + ' m²'}{' '}
            {property.distance && width <= 500 ? <br /> : ' / '}
            {(property.distance ? '徒歩' + property.distance + '分 / ' : '') +
              property.floor +
              '階 / ' +
              (parseInt(property.age) === 0
                ? '新築'
                : '築' + parseInt(property.age) + '年')}
          </div>
        </div>
      </StyledCardContent>
    </StyledCard>
  );
};

const mapStyle = [
  {
    featureType: 'landscape.man_made',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

const mapOptions = {
  gestureHandling: 'greedy',
  styles: mapStyle,
  zoomControl: false,
  clickableIcons: false,
};

const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '20px',
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '170px',
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '140px',
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '80px',
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px',
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
      },
    },
  ],
};

const buttonActiveStyle = {
  backgroundColor: theme.colors.accent,
  color: 'white',
};

const SortOrder = [
  '割安順(円)',
  '割安順(%)',
  '安い順',
  '築年が新しい順',
  '面積の広い順',
  '駅が近い順',
];

const PropertyPopup = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 15;
`;

const PopupCloseButton = styled.button`
  height: 45px;
  width: 45px;
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-right: 2px;
  border: 3px solid ${theme.colors.cherry};
  border-radius: 3px;
  color: ${theme.colors.cherry};
  background-color: white;
  cursor: pointer;
  margin-right: 15px;

  &:active {
    background-color: ${theme.colors.grey};
  }

  @media only screen and (min-width: 1000px) {
    &:hover {
      background-color: ${theme.colors.cherry};
      color: white;
    }
  }

  @media only screen and (max-width: 500px) {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }
`;

const defaultCenter = {
  lat: 35.681702400836386,
  lng: 139.76714638757284,
};

const parsePrice = (p) => {
  let parsedPrice = parseInt(p);
  if (parsedPrice >= 10000) {
    return parsedPrice / 10000 + '万';
  } else {
    return parsedPrice;
  }
};

const CustomMarker = styled.div`
  color: white;
  background: ${(props) => props.color};
  padding: ${(props) => props.size};
  border: solid white ${(props) => props.stroke};
  border-radius: 100%;
  z-index: ${(props) => props.zIndex};
  hover: cursor;
`;

const SurroundingInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 30%;
  min-width: 300px;
  z-index: 9;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  padding: 15px;
  transform: translate(-50%, -50%);

  h2 {
    margin-top: 0;
    margin-bottom: 1px;
    padding-bottom: 10px;
    font-size: 1.2rem;
    color: ${theme.colors.textGrey};
    border-bottom: 1px solid ${theme.colors.darkGrey};
  }
`;

const SurroundingInfoItem = styled.div`
  height: 3rem;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.darkGrey};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 200ms ease-in-out;
  position: relative;
  color: ${theme.colors.textGrey};

  &:hover {
    background-color: ${theme.colors.grey};
  }

  span {
    font-weight: bold;
    margin: 0 10px;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const ButtonLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ show }) => (show ? '100%' : 0)};
  transition: opacity 500ms ease-in-out;
  pointer-events: none;
`;

const Spinner = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid ${(props) => props.theme.colors.accent};
  border-radius: 50%;
  min-width: ${(props) => props.size};
  min-height: ${(props) => props.size};
  animation: ${spin} 1s linear infinite;
  pointer-events: none;
`;

function MapSearchPageCopy({ width, height, showHeader, showFooter, match }) {
  // map
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [center, setCenter] = useState(defaultCenter);

  const [zoom, setZoom] = useState(14);
  const [markerSize, setMarkerSize] = useState('');
  const [showSurrInfo, setShowSurrInfo] = useState(false);

  const [selectedProperty, setSelectedProperty] = useState('');
  const [hoveredProperty, setHoveredProperty] = useState('');

  const [placesService, setPlacesService] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);

  // properties
  const [unfilteredProperties, setUnfilteredProperties] = useState([]);

  const [property, setProperty] = useState(null);

  const [filters, setFilters] = useState(null);
  const [filterOverlayOpen, setFilterOverlayOpen] = useState(false);

  const [fetching, setFetching] = useState(true);

  const [sortOrder, setSortOrder] = useState(0);

  // pager
  const [page, setPage] = useState(0);
  const [totalProps, setTotalProps] = useState(0);

  // property list
  const [listMode, setListMode] = useState(false);
  const list = useRef(null);

  const [fetchingSurroundItems, setFetchingSurroundItems] = useState(false);
  const [surroundItems, setSurroundItems] = useState([]);
  const [surroundInfoType, setSurroundInfoType] = useState('');
  const [surroundInfoOverlayOpen, setSurroundInfoOverlayOpen] = useState(false);
  const [pagenation, setPagenation] = useState(null);

  // slider
  const slider = useRef(null);

  const history = useHistory();

  useLayoutEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    showFooter(false);
    return () => {
      showHeader(true);
      showFooter(true);
    };
  }, [showHeader, showFooter]);

  useEffect(() => {
    if (listMode || property) {
      document.querySelector('html').style.overflow = '';
      document.querySelector('body').style.overflow = '';
    } else {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    }
  }, [listMode, property]);

  useEffect(() => {
    const parsedData = parseUrl(
      history.location,
      cityNameList,
      nameStation,
      landmarks,
      urlFilters,
      urlTitles,
      filterNames
    );
    document.title = parsedData.title;
    document.getElementsByName('description').item(0).content =
      parsedData.description;
    document.getElementsByName('keywords').item(0).content =
      parsedData.keywords;

    if (parsedData.center) {
      setCenter(parsedData.center);
    }
    if (parsedData.zoom) {
      setZoom(parseInt(parsedData.zoom));
    }
    const sort = parsedData.sort;
    if (sort && sort != sortOrder) {
      setSortOrder(sort);
    }

    //フィルターがあってかつsortOrderが変更されていない場合はフィルターセットして再fetch
    if (parsedData.filters || !sort || sort == sortOrder) {
      setFilters(parsedData.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      document.querySelector('html').style.overflow = '';
      document.querySelector('body').style.overflow = '';
    };
  }, []);

  const debounceFunction = useRef(
    _.debounce((f) => {
      if (isMounted.current) {
        f();
      }
    }, 200)
  );

  useEffect(() => {
    if (maps) {
      setPlacesService(new maps.places.PlacesService(map));
      setAutocompleteService(new maps.places.AutocompleteService());
    }
  }, [maps, map]);

  useEffect(() => {
    if (width < 800) {
      setMarkerSize('4.5px');
    } else {
      setMarkerSize('6px');
    }
  }, [width]);

  useEffect(() => {
    if (map && maps) {
      fetchData(center, map.getBounds(), zoom, parseFilter(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, map, maps]);

  useEffect(() => {
    setUnfilteredProperties(handleSort(unfilteredProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder]);

  //#region Sort
  const handleSort = useCallback(
    (p) => {
      let sortedProps = p;
      switch (SortOrder[sortOrder]) {
        case SortOrder[0]:
          sortedProps.sort(
            (a, b) =>
              parseFloat(b.est_rent) -
              parseFloat(b.set_rent) -
              (parseFloat(a.est_rent) - parseFloat(a.set_rent))
          );
          break;
        case SortOrder[1]:
          // setProperties(properties.slice(0).sort((a,b) => (parseFloat(b.est_rent) / parseFloat(b.set_rent)) - (parseFloat(a.est_rent) / parseFloat(a.set_rent))));
          sortedProps.sort(
            (a, b) => parseFloat(b.cheap_ratio) - parseFloat(a.cheap_ratio)
          );
          break;
        case SortOrder[2]:
          sortedProps.sort(
            (a, b) => parseFloat(a.set_rent) - parseFloat(b.set_rent)
          );
          break;
        case SortOrder[3]:
          sortedProps.sort((a, b) => parseFloat(a.age) - parseFloat(b.age));
          break;
        case SortOrder[4]:
          sortedProps.sort((a, b) => parseFloat(b.area) - parseFloat(a.area));
          break;
        case SortOrder[5]:
          sortedProps.sort(
            (a, b) =>
              parseFloat(a.nearest_minute) - parseFloat(b.nearest_minute)
          );
          break;
        default:
          break;
      }
      return sortedProps;
    },
    [sortOrder]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSortParam = useCallback((key, value) => {
    const query = new URLSearchParams(window.location.search);
    query.set('sort', SortOrder.indexOf(value));

    history.replace(history.location.pathname + '?' + query.toString());
  });

  const handleInvalidImage = (index) => {
    unfilteredProperties.splice(index, 1);
    setUnfilteredProperties(unfilteredProperties.slice(0));
  };

  // Map Callback Functions
  const afterDataFetch = useCallback(
    async (response) => {
      if (!response.ok) return;

      const json = await response.json();
      if (isMounted.current) {
        if (json.error_code === 100) {
          setUnfilteredProperties(
            handleSort(filterMissingImages(json.bukkens))
          );

          if (json.bukken_num < json.bukkens.length) {
            setTotalProps(json.bukkens.length);
          } else {
            setTotalProps(
              json.bukkens.length < 100 && json.bukken_num < 200
                ? json.bukkens.length
                : json.bukken_num
            );
          }
        } else {
          setUnfilteredProperties([]);
          setTotalProps(0);
        }
        setPage(0);
      }
    },
    [handleSort]
  );

  const fetchData = useCallback(
    async (location, bounds, zoom, filters) => {
      setFetching(true);

      try {
        const response = await fetch(
          `https://direct2.tekiseiyachin.com/zenkoku/area-search3.php?lat=` +
            location.lat +
            `&lng=` +
            location.lng +
            `&nlat=` +
            bounds.getNorthEast().lat() +
            `&slat=` +
            bounds.getSouthWest().lat() +
            `&elng=` +
            bounds.getNorthEast().lng() +
            `&wlng=` +
            bounds.getSouthWest().lng() +
            `&zoom=` +
            zoom +
            `&limit=100` +
            filters,
          {
            method: 'GET',
          }
        );

        await afterDataFetch(response);
      } catch (error) {
        console.log(error);
      }

      setFetching(false);
    },
    [afterDataFetch]
  );

  const updateUrl = useCallback(
    (c, z, filters, showConvenienceStore) => {
      const query = new URLSearchParams(window.location.search);

      query.set('center', `${c.lat},${c.lng}`);
      query.set('zoom', z);

      let fParams = '';
      let sParams = '';
      for (const pair of query.entries()) {
        if (pair[0] === 'center' || pair[0] === 'zoom') {
          continue;
        } else if (pair[0] === 'sort') {
          sParams += `&sort=${pair[1]}`;
          continue;
        }
        query.set(pair[0], pair[1]);
        fParams += `&${pair[0]}=${pair[1]}`;
      }
      if (fParams == '') {
        fParams = constructFilterUrl(filters);
      }

      const centerParam = query.get('center');
      const zoomParam = query.get('zoom');

      let surrondInfo = '';
      if (showConvenienceStore) surrondInfo = '&surroundInfo=convenienceStore';

      history.replace(
        `/map?center=${centerParam}&zoom=${zoomParam}${fParams}${sParams}${surrondInfo}`
      );
    },
    [history]
  );

  const hideInfoWindow = () => {
    document.querySelectorAll('div[role="dialog"]').forEach((div) => {
      div.parentNode.remove();
    });
  };

  const onLoad = useCallback(async function callback({ map, maps }) {
    setMap(map);
    setMaps(maps);
  }, []);

  const onZoomChange = useCallback(
    async (zoom) => {
      if (map) {
        setZoom(zoom);
        updateUrl(center, zoom, filters);
        hideInfoWindow();
        //await fetchData(center, bounds, zoom, searchFilters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [center, filters, map, updateUrl]
  );

  const onDrag = useCallback(
    async (e) => {
      if (map) {
        setSelectedProperty('');
        const centerParam = {
          lat: e.center.lat(),
          lng: e.center.lng(),
        };
        setCenter(centerParam);
        setZoom(e.zoom);
        hideInfoWindow();
        updateUrl(centerParam, e.zoom, filters);
        //await fetchData(centerParam, bounds, e.zoom, searchFilters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, map, updateUrl]
  );

  const updateFilterParams = (filters) => {
    const params = constructFilterUrl(filters);
    const sParams = `?center=${center.lat},${center.lng}&zoom=${zoom}`;
    const sSortOrder = `&sort=${sortOrder}`;
    history.replace(sParams + params + sSortOrder);
  };

  const getColorFromPrice = (price, estPrice) => {
    const baseColor = [212, 212, 212];
    if (estPrice) {
      const diff = Math.max(Math.min((estPrice - price) / price / 0.3, 1), 0);
      const color = [
        baseColor[0] + (220 - baseColor[0]) * diff,
        baseColor[1] + (20 - baseColor[1]) * diff,
        baseColor[2] + (60 - baseColor[2]) * diff,
      ];
      return `rgb(${color[0]},${color[1]},${color[2]})`;
    } else {
      return `rgb(${baseColor[0]},${baseColor[1]},${baseColor[2]})`;
    }
  };

  const isSelectedOrHovered = (bid) => {
    return hoveredProperty === bid || selectedProperty === bid;
  };

  const getMarkerColor = (grid) => {
    let color;
    if (isSelectedOrHovered(grid.bid)) {
      color = 'green';
    } else {
      color = getColorFromPrice(grid.set_rent, grid.est_rent);
    }
    return color;
  };

  const onMarkerClick = useCallback(
    (grid, index) => {
      setSelectedProperty(grid.bid);

      if (width < 900) {
        if (slider.current) {
          slider.current.slickGoTo(
            unfilteredProperties.findIndex((p) => p.bid === grid.bid)
          );
        }
      } else {
        const p = Math.floor(index / propertiesPerPage);
        const selectedIndex = index % propertiesPerPage;

        setPage(p);

        const scrollTopValue = list.current.children[selectedIndex].getBoundingClientRect().y - list.current.getBoundingClientRect().y;
        list.current.scrollBy({top: scrollTopValue , left: 0, behavior: 'smooth'});
      }
    },
    [unfilteredProperties, width]
  );

  const onSurroundInfoClick = useCallback((map, maps, lat, lng, name) => {
    const infoWindowDom = document.querySelectorAll('div[role="dialog"]');
    if (infoWindowDom) {
      hideInfoWindow();
    }
    const infoWindow = new maps.InfoWindow({
      content: name,
      maxWidth: 300,
    });
    const position = new maps.LatLng(lat, lng);
    const marker = new maps.Marker({
      map,
      position,
    });
    infoWindow.open(map, marker);
    marker.visible = false;
  }, []);

  const hideProperties = useCallback(() => {
    setSelectedProperty('');
  }, []);

  const onChildClick = useCallback((_, childProps) => {
    childProps.onClick();
  }, []);

  const convertResult = (results) => {
    const items = results.map((result) => ({
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
      icon: result.icon,
      types: result.types,
      name: result.name,
      placeId: result.place_id,
    }));
    return items;
  };

  const searchCallback = useCallback(
    (results, status, placeSearchPagination) => {
      setFetchingSurroundItems(true);
      const items = convertResult(results);

      if (placeSearchPagination.hasNextPage) {
        const getNextPage = () => {
          // Note: nextPage will call the same handler function as the initial call
          placeSearchPagination.nextPage();
        };
        setPagenation(getNextPage);
      }
      surroundItems.push(...items);
      setFetchingSurroundItems(false);
    },
    [surroundItems]
  );

  const fetchSurroundInfo = useCallback(
    (placeType) => {
      const lat = center.lat;
      const lng = center.lng;
      let keyword = '';
      let type = [placeType];
      let radius = 1700;
      if (maps) {
        if (placeType === 'nursery') {
          keyword = 'nursery school';
          type = [];
        }
        if (placeType === 'government') {
          keyword = 'Government office';
          type = [];
        }
        if (placeType === 'supermarket') {
          keyword = 'supermarket';
          type = [];
        }
        if (zoom > 15) {
          radius = 500;
        }
        if (zoom < 14) {
          radius = 3000;
        }

        const service = new maps.places.PlacesService(map);
        const location = new maps.LatLng(lat, lng);
        const request = {
          location,
          keyword,
          radius,
          type,
        };

        service.nearbySearch(request, searchCallback);
        setSurroundItems(surroundItems);
        setShowSurrInfo(true);
      }
    },
    [center.lat, center.lng, map, maps, searchCallback, surroundItems, zoom]
  );

  useEffect(() => {
    if (surroundInfoType) {
      fetchSurroundInfo(surroundInfoType);
    }
  }, [fetchSurroundInfo, surroundInfoType]);

  return (
    <Wrapper height={height}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries: ['places'],
        }}
        defaultCenter={defaultCenter}
        center={center}
        zoom={zoom}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={onLoad}
        onZoomAnimationEnd={onZoomChange}
        onClick={() => {
          hideProperties();
          hideInfoWindow();
        }}
        onChildClick={width < 850 ? onChildClick : undefined}
        onDragEnd={onDrag}
      >
        {!listMode &&
          unfilteredProperties &&
          unfilteredProperties
            .slice(0)
            .reverse()
            .map((grid, index) => {
              const zIndex = unfilteredProperties.length - index;
              return (
                <CustomMarker
                  key={grid.bid}
                  lat={grid.lat}
                  lng={grid.lon}
                  color={getMarkerColor(grid)}
                  stroke={isSelectedOrHovered(grid.bid) ? '3px' : '2px'}
                  zIndex={
                    isSelectedOrHovered(grid.bid)
                      ? unfilteredProperties.length
                      : zIndex
                  }
                  onClick={() => {
                    onMarkerClick(grid, unfilteredProperties.length - index);
                  }}
                  size={markerSize || '6px'}
                />
              );
            })
        }
        {showSurrInfo &&
          surroundItems &&
          surroundItems
            .filter(
              (x, i, self) =>
                self.findIndex((e) => e.placeId === x.placeId) === i
            )
            .map((item) => (
              <SurroundInfoMarker
                key={item.placeId}
                lat={item.lat}
                lng={item.lng}
                style={{ width: '20px', height: '20px' }}
                onClick={() => {
                  onSurroundInfoClick(map, maps, item.lat, item.lng, item.name)
                }}
                type={surroundInfoType}
              />
            ))
        }
      </GoogleMapReact>
      <Toolbar>
        <SearchbarContainer>
          <Searchbar1
            initPlacesService={false}
            mapPlacesService={placesService}
            autocompleteService={autocompleteService}
            onSearch={(c, defaultZoom) => {
              map.panTo(c);
              setCenter(c);
              fetchData(c, map.getBounds(), defaultZoom, parseFilter(filters));
              updateUrl(c, defaultZoom, filters);
            }}
          />
        </SearchbarContainer>
        <VerticalToolbar>
          <StyledButton1
            onClick={() => {
              setFilterOverlayOpen(!filterOverlayOpen);
            }}
            width={'120px'}
            justifyContent={width > 500 ? 'flex-start' : 'center'}
            style={parseFilter(filters) ? buttonActiveStyle : {}}
          >
            <FaFilter
              size="16"
              style={{ margin: '0 5px' }}
              color={parseFilter(filters) ? 'white' : theme.colors.accent}
            />
            <span style={{ width: '100%', textAlign: 'center' }}>絞り込み</span>
          </StyledButton1>
          <StyledButton1
            onClick={() => {
              setSelectedProperty('');
              setSurroundInfoOverlayOpen(!surroundInfoOverlayOpen);
            }}
            width={'120px'}
            justifyContent={width > 500 ? 'flex-start' : 'center'}
            style={surroundInfoType ? buttonActiveStyle : {}}
          >
            <FaMap
              style={{
                margin: '0 5px',
                minWidth: '1rem',
              }}
              size={18}
              color={surroundInfoType ? 'white' : theme.colors.accent}
            />
            <span style={{ width: '100%', textAlign: 'center' }}>周辺施設</span>
          </StyledButton1>
          {surroundInfoType && (
            <div
              onClick={() => {
                setShowSurrInfo(false);
                setSurroundInfoType('');
              }}
              style={{
                position: 'absolute',
                top: '103px',
                left: '117px',
              }}
            >
              <MdClose
                size="15"
                color={'#FFFFFF'}
                style={{
                  background: '#8f8181',
                  padding: '3px',
                  borderRadius: '50%',
                  border: 'solid white',
                }}
              />
            </div>
          )}
        </VerticalToolbar>
      </Toolbar>
      <PropertyCounter>
        {parseInt(totalProps).toLocaleString()} 件の内{' '}
        {unfilteredProperties.length.toLocaleString()} 件を表示中
      </PropertyCounter>
      {width <= 900 && (
        <Toggle listMode={listMode}>
          {listMode ? (
            <>
              <button
                onClick={() => {
                  setListMode(!listMode);
                  setSelectedProperty('');
                }}
              >
                <FiMapPin style={{ marginRight: '5px' }} />
                <p>マップ</p>
              </button>
              <span>{unfilteredProperties.length + '件'}</span>
            </>
          ) : (
            <button
              onClick={() => {
                setListMode(!listMode);
                setSelectedProperty('');
              }}
            >
              <FaList size="15" style={{ marginRight: '5px' }} />
              <p>リスト</p>
            </button>
          )}
        </Toggle>
      )}
      {width < 850 && selectedProperty !== '' && (
        <PropertyWindow show={true}>
          {unfilteredProperties.length > 0 && (
            <Slider
              {...sliderSettings}
              beforeChange={(current, next) => {
                setSelectedProperty(unfilteredProperties[next].bid);
                map.panTo({
                  lat: parseFloat(unfilteredProperties[next].lat),
                  lng: parseFloat(unfilteredProperties[next].lon),
                });
              }}
              ref={slider}
            >
              {unfilteredProperties.map((property, index) => (
                <div key={property.bid}>
                  <Card
                    property={property}
                    width={width}
                    color={getColorFromPrice(
                      property.set_rent,
                      property.est_rent
                    )}
                  />
                </div>
              ))}
            </Slider>
          )}
        </PropertyWindow>
      )}
      {filterOverlayOpen && (
        <SlideIn
          show={filterOverlayOpen}
          zIndex={10}
          onClick={(e) => {
            if (
              filterOverlayOpen &&
              e.target.getAttribute('id') === 'filter-wrapper'
            ) {
              setFilterOverlayOpen(false);
            }
          }}
          id={'filter-wrapper'}
        >
          <FiltersWindow show={true}>
            <FiltersWindowHeader>条件を入力</FiltersWindowHeader>
            <CloseFilterSettingsButton
              onClick={() => setFilterOverlayOpen(false)}
            >
              <MdClose size="25" />
            </CloseFilterSettingsButton>
            <FiltersWindowList id="filtersWindowList">
              <FilterBox
                filterOverlayOpen={true}
                setFilterOverlayOpen={setFilterOverlayOpen}
                showCloseBtn={filterOverlayOpen && width <= 1000}
                closeOnSubmit={filterOverlayOpen && width <= 1000}
                hideHeader={width > 1000}
                filterObject={filters}
                updateFilterParams={updateFilterParams}
              />
            </FiltersWindowList>
          </FiltersWindow>
        </SlideIn>
      )}
      {surroundInfoOverlayOpen && (
        <SlideIn
          show={surroundInfoOverlayOpen}
          zIndex={30}
          onClick={(e) => {
            if (
              surroundInfoOverlayOpen &&
              e.target.getAttribute('id') === 'surroundInfo-wrapper'
            ) {
              setSurroundInfoOverlayOpen(false);
            }
          }}
          id={'surroundInfo-wrapper'}
        >
          <SurroundingInfo>
            <CloseFilterSettingsButton
              onClick={() => setSurroundInfoOverlayOpen(false)}
            >
              <MdClose size="25" color={theme.colors.textGrey} />
            </CloseFilterSettingsButton>
            <h2>周辺施設</h2>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'convenience_store') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('convenience_store');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaShoppingBasket size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>コンビニ</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'convenience_store' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'supermarket') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('supermarket');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaShoppingCart size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>スーパー</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'supermarket' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'hospital') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('hospital');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaHospital size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>病院</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'hospital' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'nursery') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('nursery');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaChild size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>保育園</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'nursery' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'park') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('park');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaTree size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>公園</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'park' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'gym') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('gym');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaDumbbell size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>ジム</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'gym' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
            <SurroundingInfoItem
              onClick={() => {
                if (surroundInfoType === 'government') {
                  setSurroundInfoType('');
                } else {
                  setSurroundInfoType('government');
                }
                setSurroundItems([]);
              }}
            >
              <div
                style={{
                  position: 'relative',
                  margin: width > 500 ? '0 5px' : 0,
                }}
              >
                <FaBuilding size={18} />
                <ButtonLoaderContainer show={fetchingSurroundItems}>
                  <Spinner size="25px" />
                </ButtonLoaderContainer>
              </div>
              <span>役所</span>
              <div style={{ left: '10px', position: 'absolute' }}>
                {surroundInfoType === 'government' ? (
                  <MdCheckBox size={25} color={theme.colors.accent} />
                ) : (
                  <MdCheckBoxOutlineBlank size={25} />
                )}
              </div>
            </SurroundingInfoItem>
          </SurroundingInfo>
        </SlideIn>
      )}
      {(listMode || width > 900) && map && (
        <Properties show={listMode}>
          <PropertiesWindowList ref={list}>
            <PropertiesWindowTitle>
              {width > 900 && <span>{unfilteredProperties.length + '件'}</span>}
              {width <= 900 && (
                <FilterButton
                  style={{ marginRight: '10px' }}
                  onClick={() => setFilterOverlayOpen(true)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaFilter
                      size="14"
                      style={{
                        marginLeft: '3px',
                        marginRight: '7px',
                        marginBottom: '2px',
                      }}
                      color={theme.colors.accent}
                    />
                    条件を入力
                  </div>
                </FilterButton>
              )}
              <Select
                width="180px"
                icon={
                  <FaSortAmountDown
                    style={{
                      marginRight: '10px',
                      marginBottom: '2px',
                      minWidth: '16px',
                    }}
                    color={theme.colors.accent}
                  />
                }
                label={'割安順(円)'}
                options={SortOrder}
                onSelected={setSortParam}
                selected={SortOrder[sortOrder]}
                bold
                borderOn
                fontSize="0.9rem"
              />
            </PropertiesWindowTitle>
            {unfilteredProperties
              .slice(
                page * propertiesPerPage,
                Math.min(page * propertiesPerPage + propertiesPerPage),
                unfilteredProperties.length - 1
              )
              .map((property, index) => (
                <PropertyCard
                  key={property.bid}
                  property={property}
                  rowLayout={true}
                  stretch={true}
                  image={property.image === '' ? Placeholder : property.image}
                  windowWidth={width}
                  station={
                    property.nearest_station_name && property.nearest_minute
                      ? property.nearest_station_name +
                        '駅 ' +
                        property.nearest_minute +
                        '分'
                      : null
                  }
                  onInvalidImage={() =>
                    handleInvalidImage(page * propertiesPerPage + index)
                  }
                  selected={property.bid === selectedProperty}
                  onMouseEnter={() =>
                    debounceFunction.current(() =>
                      setHoveredProperty(property.bid)
                    )
                  }
                  onMouseLeave={() =>
                    debounceFunction.current(() => setHoveredProperty(''))
                  }
                />
              ))}
            <Pager>
              <PageButton
                onClick={() => {
                  if (page - 1 >= 0) {
                    setPage(Math.max(page - 1, 0));
                    list.current.scrollTop = 0;
                  }
                }}
              >
                {'<'}
              </PageButton>
              {[
                ...Array(
                  Math.ceil(unfilteredProperties.length / propertiesPerPage)
                ).keys(),
              ].map((i) => (
                <PageButton
                  selected={i === page}
                  key={i}
                  onClick={() => {
                    setPage(i);
                    list.current.scrollTop = 0;
                  }}
                >
                  {i + 1}
                </PageButton>
              ))}
              <PageButton
                onClick={() => {
                  if (
                    page + 1 <
                    Math.ceil(unfilteredProperties.length / propertiesPerPage)
                  ) {
                    setPage(
                      Math.min(
                        page + 1,
                        Math.ceil(
                          unfilteredProperties.length / propertiesPerPage
                        ) - 1
                      )
                    );
                    list.current.scrollTop = 0;
                  }
                }}
              >
                {'>'}
              </PageButton>
            </Pager>
          </PropertiesWindowList>
          <Loader show={fetching} />
        </Properties>
      )}
      {property && (
        <PropertyPopup>
          <PropertyPage
            propertyId={property}
            width={width}
            maps={maps}
            map={map}
            backButton={
              <PopupCloseButton
                onClick={() =>
                  history.push(
                    window.location.search.replace(`&id=${property}`, '')
                  )
                }
              >
                <FaChevronLeft size="18" />
              </PopupCloseButton>
            }
          />
        </PropertyPopup>
      )}
    </Wrapper>
  );
}

export default withRouter(MapSearchPageCopy);
