// Copyright 2021, Karitoku, All rights reserved

import { useEffect, useState } from 'react';
import cityNameList from './data/city_names.json';
import urlFilters from './data/urlFilters.json';
import urlTitles from './data/urlTitles.json';
import landmarks from './data/landmarks.json';
import nameStation from './data/name_station.json';
import { filterNames } from './data/filter_name';

export const filterDuplicates = (properties) => {
  return properties.filter(
    (property, index, self) =>
      index ===
      self.findIndex(
        (p) =>
          p.name === property.name ||
          (p.address === property.address &&
            p.layout === property.layout &&
            p.area === property.area &&
            p.age === property.age)
      )
  );
};

export const filterMissingImages = (properties) => {
  return properties.filter(
    (property) =>
      property.image !== '' &&
      property.image !== 'None' &&
      property.image !== 'https://maintenance.suumo.jp/maintenance.jpg'
  );
};

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};

export const saveToHistory = (property, onSaved) => {
  const historyKey = 'history';

  let restoredHistory = JSON.parse(localStorage.getItem(historyKey)) || [];
  let duplicateIndex = -1;
  for (let i = 0; i < restoredHistory.length; i++) {
    if (restoredHistory[i].item.suumoLink === property.suumoLink) {
      duplicateIndex = i;
      break;
    }
  }
  if (duplicateIndex !== -1) {
    restoredHistory.splice(duplicateIndex, 1);
  }
  restoredHistory.push({ id: Date.now(), item: property });
  localStorage.setItem(historyKey, JSON.stringify(restoredHistory));
  if (onSaved) {
    onSaved(restoredHistory);
  }
};

export const getTotalExpenditure = (property) => {
  return property.set_rent * 2 + property.shikikin + property.reikin;
};

export const getClosestStation = (traffics) => {
  if (!traffics || traffics.length === 0) return null;
  let lowest = Number.POSITIVE_INFINITY;
  let station, line;
  for (let i = 0; i < traffics.length; i++) {
    if (traffics[i].minute < lowest) {
      lowest = traffics[i].minute;
      station = traffics[i].station;
      line = traffics[i].line;
    }
  }
  return line + '/' + station + '駅 歩' + lowest + '分';
};

// extract filters from url params
export const extractFilters = (query) => {
  const filter = {};
  for (const filterName of filterNames) {
    switch (filterName) {
      case 'layouts':
        const layoutParams = query.getAll(filterName);
        if (layoutParams.length > 0) {
          filter['layouts'] = layoutParams[0];
        }
        break;
      case 'structures':
        const structureParams = query.getAll(filterName);
        if (structureParams.length > 0) {
          filter['structures'] = structureParams[0];
        }
        break;
      case 'directions':
        const directionParams = query.getAll('directions');
        if (directionParams.length > 0) {
          filter['directions'] = directionParams[0];
        }
        break;
      case 'materials':
        const materialParams = query.getAll('materials');
        if (materialParams.length > 0) {
          filter['materials'] = materialParams[0];
        }
        break;
      default:
        if (query.get(filterName) && query.get(filterName) !== '') {
          filter[filterName] = query.get(filterName);
        }
        break;
    }
  }
  return filter;
};

export const parseFilter = (filter) => {
  if (!filter) return '';
  const filterNames = Object.keys(filter);
  let params = '';
  for (const filterName of filterNames) {
    switch (filterName) {
      case 'minPrice':
        if (filter[filterName] && filter[filterName] !== '') {
          const price = parseFloat(filter[filterName].replace('万', '')) * 10;
          params += `&rent_min=${price}`;
        }
        break;
      case 'maxPrice':
        const price = parseFloat(filter[filterName].replace('万', '')) * 10;
        if (filter[filterName] && filter[filterName] !== '') {
          params += `&rent_max=${price}`;
        }
        break;
      case 'age':
        if (filter[filterName] && filter[filterName] !== '') {
          const age = filter[filterName].replace('～', '').replace('年', '');
          params += `&age_max=${age}`;
        }
        break;
      case 'minArea':
        if (filter[filterName] && filter[filterName] !== '') {
          const breadth = filter[filterName].replace('m²', '');
          params += `&area_min=${breadth}`;
        }
        break;
      case 'maxArea':
        if (filter[filterName] && filter[filterName] !== '') {
          const breadth = filter[filterName].replace('m²', '');
          params += `&area_max=${breadth}`;
        }
        break;
      case 'minutes':
        if (filter[filterName] && filter[filterName] !== '') {
          const minutes = filter[filterName].replace('分以内', '');
          params += `&minutes=${minutes}`;
        }
        break;
      case 'layouts':
        if (filter[filterName] && filter[filterName] !== '') {
          const layoutValues = filter[filterName].split(',');
          for (const value of layoutValues) {
            if (value === 'ワンルーム') {
              params += '&layouts[]=layout_1R';
            } else {
              params += '&layouts[]=layout_' + value.replace('以上', '');
            }
          }
        }
        break;
      case 'structures':
        if (filter[filterName] && filter[filterName] !== '') {
          const structureValues = filter[filterName].split(',');
          for (const structureValue of structureValues) {
            if (structureValue.includes('アパート')) {
              params += '&structures[]=pt_ap';
            }
            if (structureValue.includes('マンション')) {
              params += '&structures[]=pt_mn';
            }
          }
        }
        break;
      case 'materials':
        if (filter[filterName] && filter[filterName] !== '') {
          const materialValues = filter[filterName].split(',');
          for (const materialValue of materialValues) {
            switch (materialValue) {
              case '鉄筋コン':
                params += '&strs[]=st_rc';
                break;
              case '鉄骨':
                params += '&strs[]=st_st';
                break;
              case '木造':
                params += '&strs[]=st_wood';
                break;
              case 'その他':
                params += '&strs[]=st_other';
                break;
              default:
                params += '';
                break;
            }
          }
        }
        break;
      case 'directions':
        if (filter[filterName] && filter[filterName] !== '') {
          const directionValues = filter[filterName].split(',');
          for (const directionValue of directionValues) {
            params += '&directions[]=dir_';
            switch (directionValue) {
              case '北':
                params += 'n';
                break;
              case '北東':
                params += 'ne';
                break;
              case '東':
                params += 'e';
                break;
              case '南東':
                params += 'se';
                break;
              case '南':
                params += 's';
                break;
              case '南西':
                params += 'sw';
                break;
              case '西':
                params += 'w';
                break;
              case '北西':
                params += 'nw';
                break;
              default:
                params += '';
                break;
            }
          }
        }
        break;
      case 'bts':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=separated_toilet_bath';
        }
        break;
      case 'db':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=delivery_box';
        }
        break;
      case 'pets':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=pet_available';
        }
        break;
      case '2ndFloor':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=not_first_floor';
        }
        break;
      case 'iwmp':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=indoor_washing_machine_place';
        }
        break;
      case 'aircon':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=air_con';
        }
        break;
      case 'autoLock':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=automatic_lock';
        }
        break;
      case 'flooring':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=wooden_floor';
        }
        break;
      case 'separateSink':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=separate_sink';
        }
        break;
      case 'interphone':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=interphone_with_monitor';
        }
        break;
      case 'reheating':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=reheating';
        }
        break;
      case 'security':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=security_system';
        }
        break;
      case 'multistove':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=multi_stove';
        }
        break;
      case 'citygas':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=city_gas';
        }
        break;
      case 'elevator':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=elevator';
        }
        break;
      case 'freenet':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=free_internet';
        }
        break;
      case 'corner':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=corner_room';
        }
        break;
      case 'brhumid':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=bathroom_dehumidifier';
        }
        break;
      case 'parking':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=parking';
        }
        break;
      case 'karitoku':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&kt=1';
        }
        break;
      case 'syskitchen':
        if (filter[filterName] && filter[filterName] !== '') {
          params += '&commitments[]=system_kitchen';
        }
        break;
      default:
        break;
    }
  }
  return params;
};

export const parseUrl = (location) => {

  const urlDirs = location.pathname;
  const query = new URLSearchParams(location.search);

  const dirs = urlDirs.split('/');

  let filters = [];
  let flags = '0';
  const areaFlags = '1000000000';
  const landmarkFlags = '0100000000';
  let areaName = '';
  let lat = '';
  let lng = '';
  let zoom = 0;
  var retData = {};
  let titleReplaces = {};

  let priorityFilter = {};
  for (const dir of dirs) {
    if (dir === "map" || dir === "") {
      continue;
    }else if (dir in urlFilters) {
      //フィルターが間取りの場合は他の条件に優先される
      if (urlFilters[dir].flag === '0000000010') {
        priorityFilter['layouts'] = urlFilters[dir].filters['layouts'];
      }else{
        Object.keys(urlFilters[dir].filters).forEach(key => filters[key] = urlFilters[dir].filters[key]);
      }
      flags = (parseInt(flags, 2) | parseInt(urlFilters[dir].flag, 2)).toString(2);
      titleReplaces[urlFilters[dir].titleSymbol] = urlFilters[dir].title;
    }else if (dir.endsWith('station')) {
      flags = (parseInt(flags, 2) | parseInt(areaFlags, 2)).toString(2);
      areaName = nameStation[dir]['station_name']
      lat = nameStation[dir]['lat'];
      lng = nameStation[dir]['lon'];
      zoom = 15;
      titleReplaces['地名'] = areaName+'駅';
    }else if (dir.endsWith('landmark')) {
      flags = (parseInt(flags, 2) | parseInt(landmarkFlags, 2)).toString(2);
      areaName = landmarks[dir]['landmark_name']
      lat = landmarks[dir]['lat'];
      lng = landmarks[dir]['lng'];
      zoom = 15;
      titleReplaces['ランドマーク'] = areaName;
    }else{
      flags = (parseInt(flags, 2) | parseInt(areaFlags, 2)).toString(2);
      areaName = cityNameList[dir]['city_name'];
      lat = cityNameList[dir]['lat'];
      lng = cityNameList[dir]['lng'];
      zoom = 14;
      titleReplaces['地名'] = areaName;
    }
  }
  for (let pf in priorityFilter) {
    filters[pf] = priorityFilter[pf];
  }

  if (areaName) {
    let flagString = flags.toString(2);
    retData.center = { lat: lat, lng: lng };
    let title = urlTitles[flagString].title;
    let description = urlTitles[flagString].description;
    for (let key in titleReplaces) {
      title = title.replace('（'+key+'）', titleReplaces[key]);
      description = description.replace('（'+key+'）', titleReplaces[key]);
    }
    retData.title = title;
    retData.description = description;
    retData.keywords = areaName + ',賃貸,地図検索,マップ';
    retData.filters = filters;
    retData.hitFix = true;
    retData.zoom = zoom;
  } else {
    lat = query.get('center').split(',')[0];
    lng = query.get('center').split(',')[1];
    retData.center = { lat: parseFloat(lat), lng: parseFloat(lng) };
    retData.zoom = query.get('zoom');

    retData.title = 'ポルティ | 割安な賃貸物件マップ';
    retData.description = '割安な賃貸物件マップ。賃貸マンション・賃貸アパートを地図で探すならポルティ。';
    retData.keywords = '賃貸,地図検索,マップ';
    retData.filters = extractFilters(query);
    retData.hitFix = false;
  }
  retData.sort = query.get('sort');

  return retData;
};

export const constructFilterUrl = (filters) => {
  return Object.entries(filters)
  .map(([key, value]) => {
    if (value !== '' && value !== '指定なし') {
      return `&${key}=${value}`;
    } else {
      return '';
    }
  })
  .join('');
}

