// Copyright 2021, Karitoku, All rights reserved
import React, {
  useCallback,
  useState,
} from 'react';
import { BsArrowCounterclockwise, BsArrowRight } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import Select from '../../components/Select/Select';
import StyledButton from '../../components/StyledButton/StyledButton';
import {
  ageFilterOptions,
  areaFilterOptions,
  directionFilters,
  distanceFilterOptions,
  layoutFilters,
  materialFilters,
  priceFilterOptions,
  structureFilters,
} from '../../data/filter_options';
import theme from '../../themes/default';
import { CheckboxFilterItem } from './CheckboxFilterItem';
import { GroupedCheckboxFilters } from './GroupedCheckboxFilters';

const FilterSettings = styled.div`
  align-items: center;
  justify-content: center;
  justify-items: center;
  color: ${theme.colors.textGrey};
  display: flex;
  flex-direction: column;
  height: ${({ overlayOpen }) => (overlayOpen ? 'calc(100% - 60px)' : '0px')};
  overflow-y: ${({ overlayOpen }) => (overlayOpen ? 'visible' : 'hidden')};
  padding: 0 10px 10px 10px;
  justify-content: flex-start;
  position: relative;
  border-radius: 5px;
  min-width: 300px;
  font-size: 0.9rem;

  @media only screen and (max-width: 1000px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0px;
    padding: 0;
    z-index: 20;
    overflow-y: ${({ overlayOpen }) => (overlayOpen ? 'scroll' : 'hidden')};
    border-radius: 0px;
    min-width: auto;
    width: 100%;
    background-color: white;
    font-size: 1rem;
  }
`;

const CloseFilterSettingsButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  color: white;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  z-index: 6;
  cursor: pointer;
`;

const FilterSettingsHeader = styled.div`
  display: flex;
  height: 50px;
  font-weight: bold;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  left: 0;
  color: ${theme.colors.textGrey};
  font-size: 1rem;
  border-bottom: 1px solid ${theme.colors.grey};

  @media only screen and (max-width: 1000px) {
    background-color: ${theme.colors.accent};
    color: white;
    position: sticky;
    top: 0;
    z-index: 5;
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  }
`;

const FilterSectionHeader = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  width: 97%;
  padding: 10px 0;
  padding-left: 3%;
  margin-top: 15px;

  @media only screen and (max-width: 1000px) {
    margin-top: 0px;
    background-color: ${theme.colors.grey};
    font-size: 1rem;
  }
`;

const FilterSubmitButton = styled.div`
  position: sticky;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;

  button {
    margin: 20px 5px;
    background-color: white;

    font-weight: bold;

    &:hover {
      background-color: white;
    }
  }

  #search_button {
    width: 100%;
    border: 2px solid ${theme.colors.accent};
    color: ${theme.colors.accent};
  }

  #reset_button {
    width: 50%;
    border: 2px solid ${theme.colors.textGrey};
    color: ${theme.colors.textGrey};
  }

  @media only screen and (min-width: 1000px) {
    position: sticky;
    bottom: 0;
    #search_button {
      &:hover {
        background-color: ${theme.colors.accent};
        color: white;
      }
    }

    #reset_button {
      &:hover {
        background-color: ${theme.colors.textGrey};
        color: white;
      }
    }
  }
`;

const StyledRangeBoxContent = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  flex-shrink: 0;
`;


function FilterBox({
  filterOverlayOpen,
  setFilterOverlayOpen,
  showCloseBtn,
  closeOnSubmit,
  hideHeader,
  filterObject,
  updateFilterParams,
}) {

  //filterBox内で一時的に保持するfilter state。
  //フォームの入力に反応し、「検索」ボタンを押すとURLに反映される
  const [filters, setFilters] = useState(filterObject);

  const updateFilter = useCallback(
    (key, value) => {
      const newFilter = { [key]: value };
      setFilters({ ...filters, ...newFilter });
    },
    [filters]
  );

  const updateCheckBox = useCallback(
    (key, value, checked) => {
      if (checked) {
        if (filters[key] && filters[key] !== '') {
          const values = [...filters[key].split(','), value];
          const newValues = [...new Set(values)];
          const newFilter = { [key]: newValues.join(',') };
          setFilters({ ...filters, ...newFilter });
        } else {
          const newFilter = { [key]: value };
          setFilters({ ...filters, ...newFilter });
        }
      } else {
        if (filters[key] !== '') {
          const values = [...filters[key].split(',')].filter(
            (v) => v !== value
          );
          const newFilter = { [key]: values.join(',') };
          setFilters({ ...filters, ...newFilter });
        } else {
          const newFilter = { [key]: value };
          setFilters({ ...filters, ...newFilter });
        }
      }
    },
    [filters]
  );


  return (
    <FilterSettings overlayOpen={filterOverlayOpen}>
      {!hideHeader && (
        <FilterSettingsHeader open={filterOverlayOpen}>
          条件を入力
          <CloseFilterSettingsButton
            show={showCloseBtn}
            onClick={() => setFilterOverlayOpen(false)}
          >
            <MdClose size="25" />
          </CloseFilterSettingsButton>
        </FilterSettingsHeader>
      )}
      <FilterSectionHeader>賃料</FilterSectionHeader>
      <StyledRangeBoxContent style={{ width: '100%' }}>
        <Select
          type="minPrice"
          label="指定なし"
          width="100%"
          borderOff
          options={priceFilterOptions}
          onSelected={updateFilter}
          selected={filters.minPrice || priceFilterOptions[0]}
        />
        <div style={{ lineHeight: '40px', padding: '0 10px' }}>～</div>
        <Select
          type="maxPrice"
          label="指定なし"
          width="100%"
          borderOff
          options={priceFilterOptions}
          onSelected={updateFilter}
          selected={filters.maxPrice || priceFilterOptions[0]}
        />
      </StyledRangeBoxContent>

      <CheckboxFilterItem
        type="karitoku"
        customWidth="100%"
        onChange={updateCheckBox}
        checked={
          filters.karitoku !== undefined && filters.karitoku !== ''
        }
        value="1"
      >
        割安な物件のみ表示する
      </CheckboxFilterItem>

      <FilterSectionHeader>広さ</FilterSectionHeader>

      <StyledRangeBoxContent style={{ width: '100%' }}>
        <Select
          type="minArea"
          label="指定なし"
          width="100%"
          borderOff
          options={areaFilterOptions}
          onSelected={updateFilter}
          selected={filters.minArea || areaFilterOptions[0]}
        />
        <div style={{ lineHeight: '40px', padding: '0 10px' }}>～</div>
        <Select
          type="maxArea"
          label="指定なし"
          width="100%"
          borderOff
          options={areaFilterOptions}
          onSelected={updateFilter}
          selected={filters.maxArea || areaFilterOptions[0]}
        />
      </StyledRangeBoxContent>

      <FilterSectionHeader>築年数</FilterSectionHeader>
      <Select
        type="age"
        label="指定なし"
        width="100%"
        borderOff
        options={ageFilterOptions}
        onSelected={updateFilter}
        selected={filters.age ? filters.age : ageFilterOptions[0]}
      />

      <FilterSectionHeader>駅徒歩</FilterSectionHeader>
      <Select
        type="minutes"
        label="指定なし"
        width="100%"
        borderOff
        options={distanceFilterOptions}
        onSelected={updateFilter}
        selected={filters.minutes || distanceFilterOptions[0]}
      />

      <FilterSectionHeader>間取り</FilterSectionHeader>
      <GroupedCheckboxFilters
        type="layouts"
        label=""
        options={layoutFilters}
        optionStates={
          filters.layouts ? filters.layouts.split(',') : []
        }
        customWidth="100%"
        onChange={updateCheckBox}
      />

      <FilterSectionHeader>建物種類</FilterSectionHeader>
      <GroupedCheckboxFilters
        type="structures"
        label=""
        customWidth="100%"
        options={structureFilters}
        optionStates={
          filters.structures ? filters.structures.split(',') : []
        }
        onChange={updateCheckBox}
      />

      <FilterSectionHeader>建物構造</FilterSectionHeader>
      <GroupedCheckboxFilters
        type="materials"
        label=""
        customWidth="100%"
        options={materialFilters}
        optionStates={
          filters.materials ? filters.materials.split(',') : []
        }
        onChange={updateCheckBox}
      />

      <FilterSectionHeader>方位</FilterSectionHeader>
      <GroupedCheckboxFilters
        type="directions"
        label=""
        customWidth="100%"
        options={directionFilters}
        optionStates={
          filters.directions ? filters.directions.split(',') : []
        }
        onChange={updateCheckBox}
      />

      <FilterSectionHeader>こだわり条件</FilterSectionHeader>
      <div style={{ width: '100%' }}>
        <CheckboxFilterItem
          type="bts"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.bts}
          value="1"
        >
          バス・トイレ別
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="db"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.db}
          value="1"
        >
          宅配ボックスあり
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="pets"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.pets}
        >
          ペット相談
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="2ndFloor"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters['2ndFloor']}
        >
          2階以上住戸
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="iwmp"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.iwmp}
        >
          室内洗濯機置場
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="aircon"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.aircon}
        >
          エアコン付
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="autoLock"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.autoLock}
        >
          オートロック
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="flooring"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.flooring}
        >
          フローリング
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="separateSink"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.separateSink}
        >
          独立洗面台
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="interphone"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.interphone}
        >
          TVモニタ付きインターフォン
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="reheating"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.reheating}
        >
          追焚機能浴室
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="security"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.security}
        >
          警備会社加入
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="multistove"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.multistove}
        >
          2口以上コンロ
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="citygas"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.citygas}
        >
          都市ガス
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="elevator"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.elevator}
        >
          エレベータ
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="freenet"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.freenet}
        >
          インターネット無料
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="corner"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.corner}
        >
          角部屋
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="syskitchen"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.syskitchen}
        >
          システムキッチン
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="brhumid"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.brhumid}
        >
          浴室乾燥機
        </CheckboxFilterItem>
        <CheckboxFilterItem
          type="parking"
          value="1"
          customWidth="300px"
          onChange={updateCheckBox}
          checked={filters.parking}
        >
          駐車場
        </CheckboxFilterItem>
      </div>
      <FilterSubmitButton>
        <StyledButton
          id="reset_button"
          buttonType={true}
          onClick={() => {
            setFilters({});
          }}
        >
          <div>
            <BsArrowCounterclockwise size={18} style={{ marginRight: '5px' }} />
            リセット
          </div>
        </StyledButton>
        <StyledButton
          id="search_button"
          buttonType={true}
          onClick={() => {
            if (closeOnSubmit) setFilterOverlayOpen(false);
            updateFilterParams(filters);
            //onFilter();
          }}
        >
          <div>
            この条件で検索する
            <BsArrowRight size="24" style={{ marginLeft: '5px' }} />
          </div>
        </StyledButton>
      </FilterSubmitButton>
    </FilterSettings>
  );
}

export default FilterBox;
