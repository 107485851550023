// Copyright 2021, Karitoku, All rights reserved
import GoogleMapReact from 'google-map-react';
import { createBrowserHistory } from 'history';
import React, { useLayoutEffect, useState } from 'react';
import { BsTools } from 'react-icons/bs';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Backdrop from './components/Drawer/Backdrop';
import Navigation from './components/Navigation/Navigation';
import Popover from './components/Popover/Popover';
import Bg from './images/home/top_bg.webp';
import logo from './images/new-logo.svg';
import AboutPage from './pages/AboutPage/AboutPage';
import AssessPage from './pages/AssessPage/AssessPage';
import HistoryPage from './pages/HistoryPage/HistoryPage';
import HomePage from './pages/HomePage/HomePage';
import MapSearchPageCopy from './pages/MapSearchPage/MapSearchPageCopy';
import PropertyPage from './pages/PropertyPage/PropertyPage';
import theme from './themes/default';

const basedir = process.env.REACT_APP_BASE_DIR ?? '';
const domain = process.env.REACT_APP_DOMAIN;

const customizedhistory = createBrowserHistory({ basename: basedir });

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        min-width: 320px;
        background-color: ${theme.colors.background};
        overflow-x: hidden;
        -webkit-tap-highlight-color:transparent;
    }
    a {
        text-decoration: none;
    }
`;

const Container = styled.main`
  height: 100%;
  width: 100%;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  top: 0;
  background-color: white;
  height: 60px;
  width: 100%;
  z-index: 20;
`;

const Subtitle = styled.p`
  color: ${theme.colors.textGrey};
  font-family: ${theme.fonts.header};
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0;

  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  @media only screen and (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

const Logo = styled.img.attrs({
  src: logo,
})`
  height: 3vh;
  min-height: 40px;
  max-height: 50px;
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 6px;

  @media only screen and (max-width: 500px) {
    height: 6vh;
    margin-left: 10px;
    margin-top: 5px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.background};
  color: grey;
  font-family: ${theme.fonts.header};
  font-size: 0.8rem;
  font-weight: 300;
  justify-items: center;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    height: auto;
    flex-direction: column;
    padding: 10px;
  }

  a {
    color: grey;
    margin: 5px 10px;
  }

  button {
    font-size: 0.8rem;
    color: grey;
    margin: 5px 10px;
  }

  span {
    margin: 5px 10px;
  }
`;

const Links = styled.div`
  display: flex;
  padding: 20px 15px;

  button {
    cursor: pointer;
    border: 0;
    background-color: transparent;
    font-family: ${theme.fonts.header};
    font-weight: 300;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0 15px;
  }
`;

const FooterEnd = styled.div`
  display: flex;
  margin: 10px 0;
`;

const StyledUnderlineOnHover = styled.div`
  text-align: center;

  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
    font-weight: normal;
    justify-content: center;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const onMaintenance = false;

const Maintenance = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10%;
  color: ${theme.colors.textGrey};
`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverType, setPopoverType] = useState('');
  const width = useWindowSize()[0];
  const height = useWindowSize()[1];
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);

  const defaultLatLng = {
    lat: 35.7022589,
    lng: 139.7744733,
  };

  const bgi = new Image();
  bgi.src = Bg;

  const handleApiLoaded = ({ map, maps }) => {
    setMap(map);
    setMaps(maps);
    new maps.Marker({
      map,
      position: defaultLatLng,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries: ['places'],
        }}
        defaultCenter={defaultLatLng}
        defaultZoom={16}
        onGoogleApiLoaded={handleApiLoaded}
      />
      <GlobalStyle />
      <Router history={customizedhistory} basename={basedir}>
        <Backdrop
          show={showPopover}
          zIndex={11}
          backdropHandler={() => {
            setShowPopover(false);
          }}
        />
        <Popover
          show={showPopover}
          type={popoverType}
          setShowPopover={(t) => setShowPopover(t)}
        />
        {showHeader && (
          <Header>
            <LogoWrapper>
              <a
                href={domain ? `https://${domain}/search` : '/'}
                onClick={() => {}}
              >
                <Logo />
              </a>
              {width > 500 ? (
                <Subtitle show={true}>
                  日本初、相場が分かる不動産検索サービス
                </Subtitle>
              ) : (
                ''
              )}
              {/* <SearchIconWrapper
                id="search-icon"
                onClick={handleShowSearchBar}
              ></SearchIconWrapper> */}
            </LogoWrapper>
            <Navigation onItemSelected={() => {}} />
          </Header>
        )}

        <Container showHeader={showHeader}>
          {!onMaintenance ? (
            <Switch>
              <Route
                path="/"
                exact
                children={
                  <HomePage
                    width={width}
                    height={height}
                    map={map}
                    maps={maps}
                  />
                }
              />
              <Route path="/about" children={<AboutPage />} />
              <Route
                path={['/map/:area', '/map']}
                children={
                  <MapSearchPageCopy
                    width={width}
                    height={height - 60}
                    showHeader={(t) => setShowHeader(t)}
                    showFooter={(t) => setShowFooter(t)}
                  />
                }
              />
              <Route path="/assess" children={<AssessPage width={width} />} />
              <Route path="/history" children={<HistoryPage width={width} />} />
              <Route
                path="/chintai/:id"
                children={<PropertyPage width={width} maps={maps} map={map} />}
              />
            </Switch>
          ) : (
            <Switch>
              <Route path="/about" children={<AboutPage />} />
              <Route
                path="/"
                children={
                  <Maintenance>
                    <BsTools size="100" color={theme.colors.darkGrey} />
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1.3rem',
                        margin: '1rem 0',
                        textDecoration: 'underline',
                      }}
                    >
                      ただいまメンテナンス中です。
                    </span>
                    <span>
                      ただいまメンテナンスのため一時サービスを停止しております。
                      <br />
                      大変ご不便をおかけいたしますが、今しばらくお待ちください。
                    </span>
                  </Maintenance>
                }
              />
            </Switch>
          )}

          {showFooter && (
            <Footer>
              <Links>
                <a href="https://porty.co.jp/">
                  <StyledUnderlineOnHover>賃料査定</StyledUnderlineOnHover>
                </a>
                <a href="https://porty.co.jp/data/price/map">
                  <StyledUnderlineOnHover>
                    家賃相場マップ
                  </StyledUnderlineOnHover>
                </a>
                <button
                  onClick={() => {
                    setShowPopover(true);
                    setPopoverType('privacy');
                  }}
                >
                  <StyledUnderlineOnHover>
                    プライバシーポリシー
                  </StyledUnderlineOnHover>
                </button>
                <button
                  onClick={() => {
                    setShowPopover(true);
                    setPopoverType('tnc');
                  }}
                >
                  <StyledUnderlineOnHover>利用規約</StyledUnderlineOnHover>
                </button>
                <a href={domain ? `https://${domain}/search/about` : '/about'}>
                  <StyledUnderlineOnHover>ポルティとは</StyledUnderlineOnHover>
                </a>
                <a href="mailto:info@porty.co.jp">
                  <StyledUnderlineOnHover>お問い合わせ</StyledUnderlineOnHover>
                </a>
              </Links>
              <FooterEnd>
                <span>©ポルティ</span>
              </FooterEnd>
            </Footer>
          )}
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
