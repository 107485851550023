// Copyright 2021, Karitoku, All rights reserved

import React from 'react';
import styled from 'styled-components';

const SlideInWrapper = styled.div`
  transform: ${(props) => (props.show ? 'translateY(0)' : 'translateY(100%)')};
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 'auto')};
  position: absolute;

  transition: all 400ms ease-in-out;

  @media screen and (min-width: 768px) {
    width: calc(100% - 750px);
  }
`;

function SlideIn({ show, children, zIndex, onClick, id }) {
  return (
    <SlideInWrapper id={id} show={show} zIndex={zIndex} onClick={onClick}>
      {children}
    </SlideInWrapper>
  );
}

export default SlideIn;
