// JR山手線
export const tokyoStations = [
    {
        station_name: '秋葉原',
        station_distinct: '',
        kana: 'あきはばら',
        pref: '東京都',
        kcode: 1130222,
    },
    {
        station_name: '神田',
        station_distinct: '',
        kana: 'かんだ',
        pref: '東京都',
        kcode: 1130223,
    },
    {
        station_name: '御徒町',
        station_distinct: '',
        kana: 'おかちまち',
        pref: '東京都',
        kcode: 1130221,
    },
    {
        station_name: '大塚',
        station_distinct: '',
        kana: 'おおつか',
        pref: '東京都',
        kcode: 1130213,
    },
    {
        station_name: '東京',
        station_distinct: '',
        kana: 'とうきょう',
        pref: '東京都',
        kcode: 1130101,
    },
    {
        station_name: '駒込',
        station_distinct: '',
        kana: 'こまごめ',
        pref: '東京都',
        kcode: 1130215,
    },
    {
        station_name: '田端',
        station_distinct: '',
        kana: 'たばた',
        pref: '東京都',
        kcode: 1130216,
    },
    {
        station_name: '巣鴨',
        station_distinct: '',
        kana: 'すがも',
        pref: '東京都',
        kcode: 1130214,
    },
    {
        station_name: '西日暮里',
        station_distinct: '',
        kana: 'にしにっぽり',
        pref: '東京都',
        kcode: 1130217,
    },
    {
        station_name: '目白',
        station_distinct: '',
        kana: 'めじろ',
        pref: '東京都',
        kcode: 1130211,
    },
    {
        station_name: '池袋',
        station_distinct: '',
        kana: 'いけぶくろ',
        pref: '東京都',
        kcode: 1130212,
    },
    {
        station_name: '高田馬場',
        station_distinct: '',
        kana: 'たかだのばば',
        pref: '東京都',
        kcode: 1130210,
    },
    {
        station_name: '渋谷',
        station_distinct: '',
        kana: 'しぶや',
        pref: '東京都',
        kcode: 1130205,
    },
    {
        station_name: '日暮里',
        station_distinct: '',
        kana: 'にっぽり',
        pref: '東京都',
        kcode: 1130218,
    },
    {
        station_name: '新大久保',
        station_distinct: '',
        kana: 'しんおおくぼ',
        pref: '東京都',
        kcode: 1130209,
    },
    {
        station_name: '新宿',
        station_distinct: '',
        kana: 'しんじゅく',
        pref: '東京都',
        kcode: 1130208,
    },
    {
        station_name: '上野',
        station_distinct: '',
        kana: 'うえの',
        pref: '東京都',
        kcode: 1130220,
    },
    {
        station_name: '有楽町',
        station_distinct: '',
        kana: 'ゆうらくちょう',
        pref: '東京都',
        kcode: 1130225,
    },
    {
        station_name: '新橋',
        station_distinct: '',
        kana: 'しんばし',
        pref: '東京都',
        kcode: 1130102,
    },
    {
        station_name: '鶯谷',
        station_distinct: '',
        kana: 'うぐいすだに',
        pref: '東京都',
        kcode: 1130219,
    },
    {
        station_name: '代々木',
        station_distinct: '',
        kana: 'よよぎ',
        pref: '東京都',
        kcode: 1130207,
    },
    {
        station_name: '原宿',
        station_distinct: '',
        kana: 'はらじゅく',
        pref: '東京都',
        kcode: 1130206,
    },
    {
        station_name: '田町',
        station_distinct: '',
        kana: 'たまち',
        pref: '東京都',
        kcode: 1130228,
    },
    {
        station_name: '浜松町',
        station_distinct: '',
        kana: 'はままつちょう',
        pref: '東京都',
        kcode: 1130227,
    },
    {
        station_name: '品川',
        station_distinct: '',
        kana: 'しながわ',
        pref: '東京都',
        kcode: 1130103,
    },
    {
        station_name: '恵比寿',
        station_distinct: '',
        kana: 'えびす',
        pref: '東京都',
        kcode: 1130204,
    },
    {
        station_name: '目黒',
        station_distinct: '',
        kana: 'めぐろ',
        pref: '東京都',
        kcode: 1130203,
    },
    {
        station_name: '高輪ゲートウェイ',
        station_distinct: '',
        kana: 'たかなわげーとうぇい',
        pref: '東京都',
        kcode: 1130230,
    },
    {
        station_name: '五反田',
        station_distinct: '',
        kana: 'ごたんだ',
        pref: '東京都',
        kcode: 1130202,
    },
    {
        station_name: '大崎',
        station_distinct: '',
        kana: 'おおさき',
        pref: '東京都',
        kcode: 1130201,
    },
];

// JR大阪環状線
export const osakaStations = [
    {
        station_name: '福島',
        station_distinct: '',
        kana: 'ふくしま',
        pref: '大阪府',
        kcode: 1162309,
    },
    {
        station_name: '桜ノ宮',
        station_distinct: '',
        kana: 'さくらのみや',
        pref: '大阪府',
        kcode: 1162312,
    },
    {
        station_name: '京橋',
        station_distinct: '',
        kana: 'きょうばし',
        pref: '大阪府',
        kcode: 1161724,
    },
    {
        station_name: '天満',
        station_distinct: '',
        kana: 'てんま',
        pref: '大阪府',
        kcode: 1162311,
    },
    {
        station_name: '大阪',
        station_distinct: '',
        kana: 'おおさか',
        pref: '大阪府',
        kcode: 1160214,
    },
    {
        station_name: '新今宮',
        station_distinct: '',
        kana: 'しんいまみや',
        pref: '大阪府',
        kcode: 1160720,
    },
    {
        station_name: '森ノ宮',
        station_distinct: '',
        kana: 'もりのみや',
        pref: '大阪府',
        kcode: 1162315,
    },
    {
        station_name: '天王寺',
        station_distinct: '',
        kana: 'てんのうじ',
        pref: '大阪府',
        kcode: 1160719,
    },
    {
        station_name: '今宮',
        station_distinct: '',
        kana: 'いまみや',
        pref: '大阪府',
        kcode: 1160721,
    },
    {
        station_name: '芦原橋',
        station_distinct: '',
        kana: 'あしはらばし',
        pref: '大阪府',
        kcode: 1162304,
    },
    {
        station_name: '大正',
        station_distinct: '',
        kana: 'たいしょう',
        pref: '大阪府',
        kcode: 1162305,
    },
    {
        station_name: '玉造',
        station_distinct: '',
        kana: 'たまつくり',
        pref: '大阪府',
        kcode: 1162316,
    },
    {
        station_name: '鶴橋',
        station_distinct: '',
        kana: 'つるはし',
        pref: '大阪府',
        kcode: 1162317,
    },
    {
        station_name: '大阪城公園',
        station_distinct: '',
        kana: 'おおさかじょうこうえん',
        pref: '大阪府',
        kcode: 1162314,
    },
    {
        station_name: '寺田町',
        station_distinct: '',
        kana: 'てらだちょう',
        pref: '大阪府',
        kcode: 1162319,
    },
    {
        station_name: '弁天町',
        station_distinct: '',
        kana: 'べんてんちょう',
        pref: '大阪府',
        kcode: 1162306,
    },
    {
        station_name: '西九条',
        station_distinct: '',
        kana: 'にしくじょう',
        pref: '大阪府',
        kcode: 1162307,
    },
    {
        station_name: '桃谷',
        station_distinct: '',
        kana: 'ももだに',
        pref: '大阪府',
        kcode: 1162318,
    },
    //野田駅？
];

// 地下鉄東山線
export const nagoyaStations = [
    {
        station_name: '本郷',
        station_distinct: '',
        kana: 'ほんごう',
        pref: '愛知県',
        kcode: 9951321,
    },
    {
        station_name: '新栄町',
        station_distinct: '',
        kana: 'しんさかえまち',
        pref: '愛知県',
        kcode: 9951311,
    },
    {
        station_name: '今池',
        station_distinct: '',
        kana: 'いまいけ',
        pref: '愛知県',
        kcode: 9951313,
    },
    {
        station_name: '藤が丘',
        station_distinct: '',
        kana: 'ふじがおか',
        pref: '愛知県',
        kcode: 9951201,
    },
    {
        station_name: '岩塚',
        station_distinct: '',
        kana: 'いわつか',
        pref: '愛知県',
        kcode: 9951303,
    },
    {
        station_name: '上社',
        station_distinct: '',
        kana: 'かみやしろ',
        pref: '愛知県',
        kcode: 9951320,
    },
    {
        station_name: '一社',
        station_distinct: '',
        kana: 'いっしゃ',
        pref: '愛知県',
        kcode: 9951319,
    },
    {
        station_name: '星ヶ丘',
        station_distinct: '',
        kana: 'ほしがおか',
        pref: '愛知県',
        kcode: 9951318,
    },
    {
        station_name: '中村公園',
        station_distinct: '',
        kana: 'なかむらこうえん',
        pref: '愛知県',
        kcode: 9951304,
    },
    {
        station_name: '本陣',
        station_distinct: '',
        kana: 'ほんじん',
        pref: '愛知県',
        kcode: 9951306,
    },
    {
        station_name: '中村日赤',
        station_distinct: '',
        kana: 'なかむらにっせき',
        pref: '愛知県',
        kcode: 9951305,
    },
    {
        station_name: '八田',
        station_distinct: '',
        kana: 'はった',
        pref: '愛知県',
        kcode: 1150802,
    },
    {
        station_name: '東山公園',
        station_distinct: '',
        kana: 'ひがしやまこうえん',
        pref: '愛知県',
        kcode: 9951317,
    },
    {
        station_name: '本山',
        station_distinct: '',
        kana: 'もとやま',
        pref: '愛知県',
        kcode: 9951316,
    },
    {
        station_name: '池下',
        station_distinct: '',
        kana: 'いけした',
        pref: '愛知県',
        kcode: 9951314,
    },
    {
        station_name: '覚王山',
        station_distinct: '',
        kana: 'かくおうざん',
        pref: '愛知県',
        kcode: 9951315,
    },
    {
        station_name: '千種',
        station_distinct: '',
        kana: 'ちくさ',
        pref: '愛知県',
        kcode: 1141104,
    },
    {
        station_name: '栄',
        station_distinct: '',
        kana: 'さかえ',
        pref: '愛知県',
        kcode: 3001201,
    },
    {
        station_name: '高畑',
        station_distinct: '',
        kana: 'たかばた',
        pref: '愛知県',
        kcode: 9951301,
    },
    {
        station_name: '名古屋',
        station_distinct: '',
        kana: 'なごや',
        pref: '愛知県',
        kcode: 1141101,
    },
    {
        station_name: '伏見',
        station_distinct: '',
        kana: 'ふしみ',
        pref: '愛知県',
        kcode: 9951309,
    },
    {
        station_name: '亀島',
        station_distinct: '',
        kana: 'かめじま',
        pref: '愛知県',
        kcode: 9951307,
    },
];