export const ServiceHeader = `サービス案内`;

export const ServiceTextTop = `ポルティは「日本で一番カンタンに住まいを探せる不動産サイト」を目指して運営されている日本初の不動産総合サービスです。
「急な引っ越しで内見する時間がない」「引っ越し先が遠すぎて内見できる物件数が限られている」「選ぶべき項目が多すぎてどの部屋がいいのかわからない」
お部屋探しの切実な悩みを解決するため、お探しの物件が相場よりお得なポルティ物件か、借りたら損をしてしまうカリソン物件か判定します。
ポルティが保有している不動産データはなんと2000万件以上！国内最大級の不動産データベースをもとにした高精度の物件査定が、難しいお部屋選びを簡単にしてくれます。
`;

export const ServiceTextBottom = `ポルティを利用するのは非常に簡単です。スーモをはじめとする不動産紹介サイトから気になった物件のリンクをコピーし、検索バーに貼り付けるだけ！*
2000万以上のデータを解析し、首都圏の不動産相場を割り出しましたので、その結果を適用し、あなたの気になる物件がポルティ物件かカリソン物件かをすぐ判定します。

*対応していない地域や不動産サイトはございますので予めご了承下さい。`;
