export const priceFilterOptions = [
  '指定なし',
  '3万',
  '3.5万',
  '4万',
  '4.5万',
  '5万',
  '5.5万',
  '6万',
  '6.5万',
  '7万',
  '7.5万',
  '8万',
  '8.5万',
  '9万',
  '9.5万',
  '10万',
  '10.5万',
  '11万',
  '11.5万',
  '12万',
  '12.5万',
  '13万',
  '13.5万',
  '14万',
  '14.5万',
  '15万',
  '15.5万',
  '16万',
  '16.5万',
  '17万',
  '17.5万',
  '18万',
  '18.5万',
  '19万',
  '19.5万',
  '20万',
  '21万',
  '22万',
  '23万',
  '24万',
  '25万',
  '26万',
  '27万',
  '28万',
  '29万',
  '30万',
  '35万',
  '40万',
  '50万',
  '100万',
];

export const ageFilterOptions = [
  '指定なし',
  '～1年',
  '～3年',
  '～5年',
  '～7年',
  '～10年',
  '～15年',
  '～20年',
  '～25年',
  '～30年',
];

export const areaFilterOptions = [
  '指定なし',
  '20m²',
  '25m²',
  '30m²',
  '40m²',
  '50m²',
  '60m²',
  '70m²',
  '80m²',
  '90m²',
  '100m²',
];

export const distanceFilterOptions = [
  '指定なし',
  '1分以内',
  '3分以内',
  '5分以内',
  '7分以内',
  '10分以内',
  '15分以内',
  '20分以内',
];

export const layoutFilters = [
  'ワンルーム',
  '1K',
  '1DK',
  '1LDK',
  '2K',
  '2DK',
  '2LDK',
  '3K',
  '3DK',
  '3LDK',
  '4K',
  '4DK',
  '4LDK',
  '5以上',
];

export const directionFilters = [
  '北',
  '北東',
  '東',
  '南東',
  '南',
  '南西',
  '西',
  '北西',
];

export const structureFilters = ['アパート', 'マンション'];

export const materialFilters = ['鉄筋コン', '鉄骨', '木造', 'その他'];
