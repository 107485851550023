import * as React from 'react';
import theme from '../../themes/default';
import { CheckboxFilterItem } from './CheckboxFilterItem';

export const GroupedCheckboxFilters = ({
  type,
  label,
  options,
  optionStates,
  customWidth,
  onChange,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        color: theme.colors.textGrey,
        flexShrink: '0',
        width: customWidth ? customWidth : 'auto',
      }}
    >
      {label && (
        <span style={{ padding: '10px', fontWeight: 'bold' }}>{label}</span>
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridAutoFlow: 'row',
        }}
      >
        {options.map((option, index) => (
          <CheckboxFilterItem
            type={type}
            key={index}
            checked={optionStates.includes(option)}
            customWidth="150px"
            onChange={onChange}
            value={option}
          >
            {option}
          </CheckboxFilterItem>
        ))}
      </div>
    </div>
  );
};
